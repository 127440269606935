import { useContext } from "react";
import GameResult from "../gameResult";
import { GameContext } from "../../App";
import "../../styles/matchHistory.css";

const MatchHistory = () => {
  const { user } = useContext(GameContext);
  const matchHistory = user?.usersettings?.matchHistory || [];

  return (
    <div className="match-history-wrapper">
      {/* Banner/Header area */}
      <header className="match-history-banner">
        <h1 className="banner-title">Your Match History</h1>
        <p className="banner-subtitle">
          Check out your past games and watch replays!
        </p>
      </header>

      {/* Main content */}
      <div className="match-history-content">
        {matchHistory.length === 0 && (
          <p className="no-matches-text">No match history found!</p>
        )}
        <div className="game-cards-container">
          {matchHistory.map((game) => (
            // Pass a unique key, e.g. gameId or date
            <GameResult key={game.gameId} gameObject={game} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MatchHistory;