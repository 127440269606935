import { GameContext } from "../../App";
import { useContext, useEffect, useState } from "react";
import "../../styles/leaderboard.css";

const Leaderboard = () => {
  const { socket } = useContext(GameContext);
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    if (socket.current) {
      socket.current.emit("send-leaderboard-info");

      socket.current.on("info-leaderboard-send", (data) => {
        const sortedLeaderboard = data.sort((a, b) => {
          if (b.mmr === a.mmr) {
            return b.wins - a.wins;
          }
          return b.mmr - a.mmr;
        });
        setLeaderboard(sortedLeaderboard);
      });
    }

    return () => {
      socket.current?.off("info-leaderboard-send");
    };
  }, []);

  const bracketRanges = {
    Omnipotent: [2401, Infinity],
    Divine: [2301, 2400],
    Celestial: [2201, 2300],
    Archon: [2101, 2200],
    Sovereign: [2001, 2100],
    Mythic: [1901, 2000],
    Legendary: [1801, 1900],
    Titanic: [1701, 1800],
    Mystical: [1601, 1700],
    Lord: [1501, 1600],
    Knight: [1401, 1500],
    Peasant: [0, 1400],
  }

  const getBracketTitle = (mmr) => {
    for (const [title, [min, max]] of Object.entries(bracketRanges)) {
      if (mmr >= min && mmr <= max) {
        return title;
      }
    }
    // In case mmr is less than 0 or something unexpected
    return "Peasant";
  };

  return (
    <div className="leaderboard-wrapper">
      {/* Banner/Header area */}
      <header className="leaderboard-banner">
        <h1 className="banner-title">Leaderboard</h1>
        <p className="banner-subtitle">
          Check out where you stand among the top players!
        </p>
      </header>

      {/* Main content */}
      <div className="leaderboard-content">
        <div className="table-container">
          <table className="leaderboardtable">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Title</th> {/* Add a table header for the bracket title */}
                <th>MMR</th>
                <th>Username</th>
                <th>Wins</th>
                <th>Losses</th>
              </tr>
            </thead>
            <tbody>
              {leaderboard.map((user, index) => (
                <tr key={user.userId}>
                  <td>{index + 1}</td>
                  <td>{getBracketTitle(user.mmr)}</td> {/* Display brackets */}
                  <td>{user.mmr}</td>
                  <td>{user.userName}</td>
                  <td>{user.wins}</td>
                  <td>{user.losses}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;