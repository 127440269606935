import { useContext, useState, useEffect, useRef } from "react"
import spellFunctions from "../spellFunctions"
import { GameContext } from "../App"
import { useNavigate } from "react-router-dom"
import Sideboard from "../components/sideboard"
// import GameLog, { logAction } from "../components/GameLog/GameLog"
import Board from "../components/board"
import InfoText from "../components/infoText"
import { rarityIndex } from "../constants"
import deckFunctions from "../deckFunctions"
import DeckPageMenu from "../components/deckPageMenu"
import soundEffects from "../gameSFX/SFX"
import authHook from "../customHooks/authHook"
import card from "../components/card"

const { reduceDeck } = deckFunctions

const Game = () => {
  authHook()
  const {
    deck,
    sideBoard,
    user,
    cards,
    rooms,
    socket,
    signedIn,
    deckList,
    setDeck,
    deckName,
    setDeckName,
    setSideBoard,
  } = useContext(GameContext)
  const navigate = useNavigate()
  const [passTurnDisabled, setPassTurnDisabled] = useState(false)
  const [stateUpdate, setStateUpdate] = useState(false)
  const [worship, setWorship] = useState({})
  const [worshipArray, setWorshipArray] = useState([])
  const firstTurn = useRef(false)
  const [resolveMulligan, setResolveMulligan] = useState(true)
  const [opponentMulligan, setOpponentMulligan] = useState(true)
  const [isTurn, setIsTurn] = useState(false)
  const room = useRef("")
  const clickNumber = useRef(0)
  const turnNumber = useRef(1)
  const gameReplay = useRef({})
  const [discardSelection, setDiscardSelection] = useState(false)
  const [discardChoices, setDiscardChoices] = useState([])
  const [discardNumber, setDiscardNumber] = useState(0)
  const [handSelection, setHandSelection] = useState(false)
  const [handChoices, setHandChoices] = useState([])
  const [handNumber, setHandNumber] = useState(0)
  const playerChoiceIndex = useRef(null)
  const [gameDeck, setGameDeck] = useState([])
  const [theirGameDeck, setTheirGameDeck] = useState([])
  const [hand, setHand] = useState([])
  const originalOpponentDeck = useRef([])
  const [opponentsHand, setOpponentsHand] = useState([])
  const [myBoard, setMyBoard] = useState([])
  const [theirBoard, setTheirBoard] = useState([])
  const [mana, setMana] = useState(0)
  const [currentMana, setCurrentMana] = useState(0)
  const [opponentsMana, setOpponentsMana] = useState(0)
  const [opponentsCurrentMana, setOpponentsCurrentMana] = useState(0)
  const [attacker, setAttacker] = useState(null)
  const [attacked, setAttacked] = useState([])
  const [theirAttacked, setTheirAttacked] = useState([])
  const [myGraveyard, setMyGraveyard] = useState([])
  const [theirGraveyard, setTheirGraveyard] = useState([])
  const [castingSpell, setCastingSpell] = useState(false)
  const [spellCast, setSpellCast] = useState(null)
  const [hideRooms, setHideRooms] = useState(false)
  const opponentUser = useRef({})
  const [expandMyGY, setExpandMyGY] = useState(false)
  const [expandOpponentGY, setExpandOpponentGY] = useState(false)
  const [extraCost, setExtraCost] = useState(0)
  const animationActive = useRef(false)
  const [togglePassTurn, setTogglePassTurn] = useState(false)
  const [animationTrigger, setAnimationTrigger] = useState(false)
  const [applyAbsolute, setApplyAbsolute] = useState(false)
  const animationStack = useRef([])
  const [gyOrArtifacts, setGyOrArtifacts] = useState(false)
  const [opponentGyOrArtifacts, setOpponentGyOrArtifacts] = useState(false)
  const [player, setPlayer] = useState({
    userName: user.userName,
    defense: 30,
    damageTaken: 0,
    type: "self",
    block: 0,
    durability: 0,
    poison: 0,
    revitalize: 0,
    spellsCasted: 0,
    healAbsorb: 0,
    sacrifices: 0,
  })
  const [opponent, setOpponent] = useState({
    userName: "Guest",
    defense: 30,
    damageTaken: 0,
    type: "opponent",
    block: 0,
    durability: 0,
    poison: 0,
    revitalize: 0,
    spellsCasted: 0,
    healAbsorb: 0,
    sacrifices: 0,
  })
  const [dontResolveUpkeep, setDontResolveUpkeep] = useState(false)
  const [spellID, setSpellID] = useState(null)
  const [usedPlayerAbility, setUsedPlayerAbility] = useState(false)
  const [extraTurn, setExtraTurn] = useState(false)
  const gameIsFinished = useRef(false)
  const [infoText, setInfoText] = useState("")
  const [usedThisAbility, setUsedThisAbility] = useState("")
  const [drawFromGraveyard, setDrawFromGraveyard] = useState(false)
  const [opponentLeft, setOpponentLeft] = useState(false)
  const attackerAnimateId = useRef(-1)
  const [opponentDrawFromGraveyard, setOpponentDrawFromGraveyard] =
    useState(false)
  const [expandMyArtifacts, setExpandMyArtifacts] = useState(false)
  const [expandOpponentArtifacts, setExpandOpponentArtifacts] = useState(false)
  const [statsLogged, setStatsLogged] = useState(false)
  const [playerSelection, setPlayerSelection] = useState(false)
  const [playerSelectionOptions, setPlayerSelectionOptions] = useState([])
  const [opponentChoice, setOpponentChoice] = useState(null)
  const [recursionTrue, setRecursionTrue] = useState(false)
  const [mulliganedCards, setMulliganedCards] = useState([])
  const gameUsers = useRef([])
  const spectate = useRef(false)
  const [sideBoarding, setSideBoarding] = useState(true)
  const [opponentSideBoarding, setOpponentSideBoarding] = useState(true)
  const [gameSideBoard, setGameSideBoard] = useState([])
  const [theirGameSideBoard, setTheirGameSideBoard] = useState([])
  const [drawnStarterCards, setDrawnStarterCards] = useState(false)
  const [myCountersArray, setMyCountersArray] = useState([])
  const [opponentsCountersArray, setOpponentsCountersArray] = useState([])
  const [onDeathGlobals, setOnDeathGlobals] = useState([])
  const [opponentDeathGlobals, setOpponentDeathGlobals] = useState([])
  const [onCastGlobals, setOnCastGlobals] = useState([])
  const [opponentCastGlobals, setOpponentCastGlobals] = useState([])
  const [onDrawGlobals, setOndrawGlobals] = useState([])
  const [opponentDrawGlobals, setOpponentDrawGlobals] = useState([])
  const [onHealGlobals, setOnHealGlobals] = useState([])
  const [opponentHealGlobals, setOpponentHealGlobals] = useState([])
  const [onPlayGlobals, setOnPlayGlobals] = useState([])
  const [opponentOnPlayGlobals, setOpponentOnPlayGlobals] = useState([])
  const [opponentCardBack, setOpponentCardBack] = useState("")
  const [myArtifacts, setMyArtifacts] = useState([])
  const [opponentArtifacts, setOpponentArtifacts] = useState([])
  const [myExileZone, setMyExileZone] = useState([])
  const [opponentExileZone, setOpponentExileZone] = useState([])
  const [spellReduction, setSpellReduction] = useState({
    reduceCost: 0,
    reducedCost: false,
    lifeCost: { payLife: false, amount: 0 },
    dontSplice: false,
    reset: false,
  })
  const [creatureReduction, setCreatureReduction] = useState({
    reduceCost: 0,
    reducedCost: false,
    lifeCost: { payLife: false, amount: 0 },
    dontSplice: false,
    reset: false,
  })
  const [myKingdomOfHeaven, setMyKingdomOfHeaven] = useState(false)
  const [opponentKingdomOfHeaven, setOpponentKingdomOfHeaven] = useState(false)
  const [myKingdomOfHell, setMyKingdomOfHell] = useState(false)
  const [opponentKingdomOfHell, setOpponentKingdomOfHell] = useState(false)
  // const [logs, setLogs] = useState([])
  const [prophecyArray, setProphecyArray] = useState([])
  const [opponentProphecyArray, setOpponentProphecyArray] = useState([])
  // const log = (message) => logAction(message, setLogs)
  const [callStack, setCallStack] = useState([])
  const andvarisTreasure = {
    name: "Andvari's Treasure",
    type: "spell",
    class: "sorcery",
    attack: null,
    defense: null,
    damageTaken: 0,
    cost: 0,
    text: "Gain 1 mana and 3 maximum life",
    abilities: ["andvarisTreasure"],
    activatesOn: ["noTarget"],
    effects: [],
    rarity: "mythic",
    id: 1000,
    image: "andvarisTreasure",
    mythology: "token",
    token: true,
  }
  const [validDeckCheck, setValidDeckCheck] = useState([])
  const [nextCastDamage, setNextCastDamage] = useState({
    spellDamage: 0,
    reset: false,
  })
  const [doubleCast, setDoubleCast] = useState({
    doubleCast: false,
    reset: false,
  })
  const gameId = useRef(0)
  const initialState = useRef({})
  const [handReveal, setHandReveal] = useState(false)
  const [theVoid, setTheVoid] = useState([])
  const [theirVoid, setTheirVoid] = useState([])
  const [timeInQueue, setTimeInQueue] = useState(0)
  const startTimer = useRef(false)
  const timerInterval = useRef(null)
  const [opponentChoosing, setOpponentChoosing] = useState(false)
  const [statTrackingDeck, setStatTrackingDeck] = useState([])
  // const inGameTimer = useRef(0)
  // const startInGameTimer = useRef(false)

  useEffect(() => {
    if (startTimer.current) {
      timerInterval.current = setInterval(() => {
        setTimeInQueue((time) => time + 1)
      }, 1000)
    } else {
      clearInterval(timerInterval.current)
    }
  }, [startTimer.current])

  useEffect(() => {
    if (socket.current) {
      // Save the ID of the socket to a variable
      socket.current.on("disconnect", () => {
        // TODO: Sync game states on successful reconnect
        if (startTimer.current) {
          startTimer.current = false
          setTimeInQueue(0)
          setInfoText(
            "You've Disconnected from Queue, check your connection stability"
          )
        } else {
          setInfoText("You've Disconnected! Trying to reconnect...")
        }
        gameIsFinished.current = true
      })

      // Closes the room at the end of a game
      socket.current.on("close-room", () => {
        if (gameUsers.current.length && gameIsFinished.current === true) {
          setOpponentLeft(true)
          setOpponent((opponent) => ({
            ...opponent,
            damageTaken: opponent.defense,
          }))
          setInfoText("Victory!")
          socket.current.emit("leave-room", room.current)
        } else if (spectate.current) {
          setInfoText("Game is over and room is closed!")
          gameIsFinished.current = true
        }
      })

      socket.current.on("player-disconnect", (data) => {
        if (gameUsers.current.includes(data)) {
          gameIsFinished.current = true
          setInfoText("Opponent disconnected waiting for reconnect...")
        }
      })

      socket.current.on("choice-player", (data) => {
        setPlayerSelection(true)
        setPlayerSelectionOptions(data.options)
        setRecursionTrue(data.recursion)
      })

      socket.current.on("sideboard-and-deck", (data) => {
        setTheirGameDeck(data.deck)
        setTheirGameSideBoard(data.sideboard)
        setOpponentCardBack((opponentCardBack) =>
          data.cardBack ? data.cardBack : opponentCardBack
        )
        originalOpponentDeck.current = [...data.deck]
        opponentUser.current = data.user
        setOpponent((opponent) => ({
          ...opponent,
          userName: data.ourName,
        }))
      })

      socket.current.on("picked-choice", (data) => {
        setIsTurn(true)
        setOpponentChoice(data.index)
        setOpponentChoosing(false)
        setRecursionTrue(data.recursion)
        logGameAction(
          { index: data.index, recursion: data.recursion },
          "opponentChoice"
        )
        if (spectate.current) {
          setPlayerSelection(false)
          setPlayerSelectionOptions([])
          setRecursionTrue(false)
        }
      })
      // Pass important board information between the clients and the server
      socket.current.on("board-update", (data) => {
        // setLogs((logs) => [...logs, ...data.logs])
        if (data.removeAbsolute) {
          setApplyAbsolute(false)
        }
        if (data?.attackAnimation) {
          toggleAttackAnimation(
            data.attacker.id,
            data.target.type === "opponent" ? "myLife" : data.target.id
          )
          setTimeout(() => updateGameState(data), 1000)
        } else {
          if (data.turnPass)
            socket.current.emit("received-turn-flip", gameId.current)
          setTimeout(() => updateGameState(data), 1100)
        }
      })

      socket.current.on("over-game", (data) => {
        if (animationStack.current.length > 0) {
          animationStack.current.push("over-game")
          animationStack.current.push(data)
        } else {
          socket.current.activeGame = false
          if (!gameIsFinished.current) {
            gameIsFinished.current = true
            if (data.winLose) setInfoText(data.winLose)
            else setInfoText("Opponent Left")
          }
          if (
            data?.winLose &&
            !isSpectator(socket.current.socketID, gameUsers.current)
          )
            socket.current.emit("log-replay", {
              gameActions: gameReplay.current,
              gameId: gameId.current,
              user: user,
              firstTurn: firstTurn.current,
              initialState: firstTurn.current ? initialState.current : null,
            })
          socket.current.emit("leave-room", room.current)
        }
      })

      socket.current.on("closed-due-to-inactivity", () => {
        gameIsFinished.current = true
        setInfoText("Game closed because both players were inactive")
      })

      socket.current.on("sideboard-post-new", (data) => {
        if (!spectate.current) {
          setTheirGameDeck(data.gameDeck)
          setTheirGameSideBoard(data.sideBoard)
          setOpponentSideBoarding(false)
        }
      })

      socket.current.on("reconnect-to-game-request", (data) => {
        setInfoText("")
        gameIsFinished.current = false
        socket.current.emit("continue-game", data)
      })

      socket.current.on("game-continue", () => {
        if (!spectate.current) {
          gameUsers.current = []
          socket.current.emit("synch-users", {
            room: room.current,
            user: socket.current.socketID,
          })
        }
      })

      socket.current.on("users-synch", (data) => {
        if (!spectate.current) {
          gameUsers.current = [data, socket.current.socketID]
        }
        // if (sideBoarding) {
        //   setInfoText("Opponent Sideboarding...")
        // } else if (opponentMulligan) {
        //   setInfoText("Opponent Mulligan...")
        // } else {
        setInfoText("")
        // }

        gameIsFinished.current = false
      })

      // The server signals that a game has started and sets the match up to be played
      socket.current.on("start-game", (data) => {
        setInfoText("Opponent Sideboarding...")
        gameUsers.current = data.users
        room.current = data.room
        socket.current.room = data.room
        socket.current.ranked = data?.ranked
        socket.current.gameId = data.gameId
        gameId.current = data.gameId
        socket.current.inQueue = false
        startTimer.current = false
        if (socket.current.socketID === data.users[data.firstPlayer]) {
          socket.current.firstTurn = true
          firstTurn.current = true
          setDontResolveUpkeep(true)
        } else {
          socket.current.firstTurn = false
        }
        startSideBoard()
      })

      socket.current.on("spectate-room", (roomData) => {
        if (!room.current) {
          room.current = roomData.room
          spectate.current = true
          setSideBoarding(false)
          setOpponentSideBoarding(false)
          socket.current.emit("current-board", {
            room: room.current,
            socketID: socket.current.socketID,
          })
        }
      })

      socket.current.on("send-current-board", (roomData) => {
        setStateUpdate((stateUpdate) => roomData)
      })

      socket.current.on("receive-current-board", (data) => {
        setTheirBoard(data.defenderCurrentBoard)
        setMyBoard(data.attackerCurrentBoard)
        setTheirGraveyard(data.theirCurrentGY)
        setMyGraveyard(data.myCurrentGY)
        setPlayer(data.myPlayer)
        setOpponent(data.theirPlayer)
        setMana(data.mana)
        setCurrentMana(data.myCurrentMana)
        setOpponentsMana(data.opponentsMana)
        setOpponentsCurrentMana(data.opponentsCurrentMana)
        setGameDeck(data.deck)
        setTheirGameDeck(data.theirDeck)
        setHand(data.myHand)
        setOpponentsHand(data.theirHand)
      })
    }

    return () => {
      if (socket.current) {
        socket.current.off("disconnect")
        socket.current.off("close-room")
        socket.current.off("player-disconnect")
        socket.current.off("choice-player")
        socket.current.off("sideboard-and-deck")
        socket.current.off("picked-choice")
        socket.current.off("board-update")
        socket.current.off("over-game")
        socket.current.off("sideboard-post-new")
        socket.current.off("reconnect-to-game-request")
        socket.current.off("game-continue")
        socket.current.off("users-synch")
        socket.current.off("start-game")
        socket.current.off("spectate-room")
        socket.current.off("send-current-board")
        socket.current.off("receive-current-board")
        if (socket.current.activeGame) {
          socket.current.emit("game-over", {
            room: room.current,
            socketID: socket.current.socketID,
            winLose: "Victory!",
            deck: { deck: statTrackingDeck, won: false },
            opponentsDeck: { deck: originalOpponentDeck.current, won: true },
            winner: {
              userName: opponentUser.current.userName,
              email: opponentUser.current.email,
            },
            loser: { userName: user.userName, email: user.email },
            gameId: gameId.current,
          })
          socket.current.activeGame = false
        }
      }
    }
  }, [deck, sideBoard, animationActive])

  useEffect(() => {
    if (
      signedIn &&
      localStorage.getItem(`defaultDeck${user.userName}`) &&
      deck.length !== 40
    ) {
      loadDeckGamePage(localStorage.getItem(`defaultDeck${user.userName}`))
    }
  }, [signedIn, user, deck])

  // Ends the game when it is lost/won
  useEffect(() => {
    if (!spectate.current) {
      if (isTurn || opponentLeft) {
        if (
          opponent.defense - opponent.damageTaken <= 0 ||
          player.defense - player.damageTaken <= 0 ||
          opponentLeft
        ) {
          // Exiting fullscreen mode
          if (document.fullscreenElement) {
            document.exitFullscreen()
          }

          socket.current.activeGame = false
          setInfoText(
            opponent.defense - opponent.damageTaken <= 0
              ? "Victory!"
              : "Defeat!"
          )
          gameIsFinished.current = true
          if (!statsLogged) {
            setStatsLogged(true)
            socket.current.emit("game-over", {
              room: room.current,
              socketID: socket.current.socketID,
              winLose:
                opponent.defense - opponent.damageTaken <= 0
                  ? "Defeat!"
                  : "Victory!",
              deck: {
                deck: deck,
                won:
                  opponent.defense - opponent.damageTaken <= 0 ? true : false,
              },
              opponentsDeck: {
                deck: originalOpponentDeck.current,
                won: opponent.defense - opponent.damageTaken > 0 ? true : false,
              },
              winner: {
                userName:
                  opponent.defense - opponent.damageTaken <= 0
                    ? user.userName
                    : opponentUser.current.userName,
                email:
                  opponent.defense - opponent.damageTaken <= 0
                    ? user.email
                    : opponentUser.current.email,
              },
              loser: {
                userName:
                  opponent.defense - opponent.damageTaken <= 0
                    ? opponentUser.current.userName
                    : user.userName,
                email:
                  opponent.defense - opponent.damageTaken <= 0
                    ? opponentUser.current.email
                    : user.email,
              },
              gameId: gameId.current,
            })
          }
        }
      }
    }
  }, [player, opponent, opponentLeft, statsLogged, opponentUser.current, user])

  useEffect(() => {
    if (
      !sideBoarding &&
      !opponentSideBoarding &&
      (resolveMulligan || opponentMulligan) &&
      !drawnStarterCards &&
      !spectate.current
    ) {
      const newDeck = gameDeck.map((card) => ({ ...card }))
      const shuffledDeck = []
      while (newDeck.length) {
        const randomIndex = Math.floor(Math.random() * newDeck.length)
        shuffledDeck.push(newDeck.splice(randomIndex, 1)[0])
      }
      if (socket.current.socketID === gameUsers.current[0]) {
        for (let i = 0; i < shuffledDeck.length; i++) {
          shuffledDeck[i].id = i
        }
      } else if (socket.current.socketID === gameUsers.current[1]) {
        for (let i = 0; i < shuffledDeck.length; i++) {
          shuffledDeck[i].id = i + 100
        }
      }
      const data = setInitialState({ deck: shuffledDeck, myHand: hand })
      const newHand = drawCards(data, 4, "self", true)
      setGameDeck(newHand.deck)
      setHand(newHand.myHand)
      setDrawnStarterCards(true)
      setInfoText("Opponent Mulligan...")
    }
  }, [
    sideBoarding,
    opponentSideBoarding,
    resolveMulligan,
    opponentMulligan,
    drawnStarterCards,
  ])

  // useEffect(() => {
  //   for (let i = 0; i < logs.length; i++) {
  //     console.log(logs[i])
  //   }
  // }, [logs])

  useEffect(() => {
    if (!castingSpell && callStack.length && isTurn) {
      const data = setInitialState({})
      updateBoardState({ ...data, spellCast: data.callStack[0] })
    }
  }, [castingSpell, callStack, isTurn])

  // Watches for data from the server to start your turn
  useEffect(() => {
    if (!spectate.current) {
      if (isTurn) {
        if (!dontResolveUpkeep) {
          resolveTurnState()
        } else {
          setDontResolveUpkeep(false)
          const data = setInitialState({
            mana: mana + 1,
            myCurrentMana: mana + 1,
          })
          updateBoardState(data)
        }
      }
    } else {
      setIsTurn(false)
    }
  }, [isTurn])

  useEffect(() => {
    if (togglePassTurn) {
      setTogglePassTurn(false)
      passTurn()
    }
  }, [togglePassTurn])

  useEffect(() => {
    if (!animationActive.current && animationStack.current.length > 0) {
      const nextAnimation =
        animationStack.current[0] === "passTurn"
          ? animationStack.current[0]
          : animationStack.current.shift()
      if (nextAnimation === "passTurn") {
        setPassTurnDisabled(false)
        setTimeout(() => {
          animationStack.current = []
          setTogglePassTurn(true)
        }, 3000)
      }

      if (nextAnimation === "over-game") {
        const data = animationStack.current.shift()

        socket.current.activeGame = false
        if (!gameIsFinished.current) {
          gameIsFinished.current = true
          if (data.winLose) setInfoText(data.winLose)
          else setInfoText("Opponent Left")
        }
        if (
          data?.winLose &&
          !isSpectator(socket.current.socketID, gameUsers.current)
        )
          socket.current.emit("log-replay", {
            gameActions: gameReplay.current,
            gameId: gameId.current,
            user: user,
            firstTurn: firstTurn.current,
            initialState: firstTurn.current ? initialState.current : null,
          })
        socket.current.emit("leave-room", room.current)
      }

      receiveDamage(
        nextAnimation.target,
        nextAnimation.index,
        nextAnimation.attacker
      )
    }
  }, [animationTrigger])

  useEffect(() => {
    if (!spectate.current) {
      if (!resolveMulligan && !opponentMulligan) {
        setApplyAbsolute(false)
        if (firstTurn.current) {
          const data = setInitialState({})
          data.theirHand.push(andvarisTreasure)
          initialState.current = data
          updateBoardState(data, null, null, null, true)
        }
      }
    }
  }, [resolveMulligan, opponentMulligan, firstTurn.current])

  useEffect(() => {
    sendSpectatorBoard({ ...stateUpdate })
  }, [stateUpdate])

  useEffect(() => {
    if (user?.userName) {
      setPlayer((player) => ({ ...player, name: user.userName }))
    }
  }, [user])

  const findCard = (data, id, cardName) => {
    const findCardInArray = (array, id, cardName) => {
      return array.find((card) => card.id === id || card.name === cardName)
    }

    const dataSets = [
      data.attackerCurrentBoard,
      data.defenderCurrentBoard,
      data.myHand,
      data.theirHand,
      data.myCurrentGY,
      data.theirCurrentGY,
      data.deck,
      data.theirDeck,
    ]

    for (const dataSet of dataSets) {
      const card = findCardInArray(dataSet, id, cardName)
      if (card) return card
    }

    return null // Card not found
  }

  function updateGameState(data) {
    if (data.isMulligan) {
      setInfoText("")
    }

    if (data.opponentOriginalDeck) {
      originalOpponentDeck.current = data.opponentOriginalDeck
    }
    if (data.tappedCreatures) {
      setTheirAttacked(data.tappedCreatures)
    }
    if (data.attackerAttacked) {
      setTheirAttacked(data.attacked)
    }
    if (data.user) {
      opponentUser.current = data.user
    }
    setMyBoard((myBoard) =>
      data.defenderCurrentBoard ? data.defenderCurrentBoard : myBoard
    )
    setPlayer((player) =>
      data.theirPlayer
        ? { ...player, ...data.theirPlayer, type: "self" }
        : player
    )

    setOpponent((opponent) =>
      data.myPlayer
        ? { ...opponent, ...data.myPlayer, type: "opponent" }
        : opponent
    )

    setOpponentsMana((opponentsMana) =>
      "mana" in data ? data.mana : opponentsMana
    )
    setOpponentsCurrentMana((opponentsCurrentMana) =>
      "myCurrentMana" in data ? data.myCurrentMana : opponentsCurrentMana
    )
    setTheirBoard((theirBoard) =>
      data.attackerCurrentBoard ? data.attackerCurrentBoard : theirBoard
    )
    setMyGraveyard((myGY) => (data.theirCurrentGY ? data.theirCurrentGY : myGY))
    setTheirGraveyard((theirGY) =>
      data.myCurrentGY ? data.myCurrentGY : theirGY
    )
    setOpponentsHand((theirHand) => (data.myHand ? data.myHand : theirHand))
    setHand((hand) => (data.theirHand ? data.theirHand : hand))
    setGameDeck((deck) => (data.theirDeck ? data.theirDeck : deck))
    setGameSideBoard((sideBoard) =>
      data.theirSideBoard ? data.theirSideBoard : sideBoard
    )
    setTheirGameDeck((theirDeck) => (data.deck ? data.deck : theirDeck))
    setTheirGameSideBoard((theirGameSideBoard) =>
      data.sideBoard ? data.sideBoard : theirGameSideBoard
    )
    setDontResolveUpkeep((dontResolveUpkeep) =>
      "dontResolveUpkeep" in data ? data.dontResolveUpkeep : dontResolveUpkeep
    )
    setOpponentDrawFromGraveyard((drawFromGraveyard) =>
      "drawFromGraveyard" in data ? data.drawFromGraveyard : drawFromGraveyard
    )
    setOpponentMulligan(false)
    setDrawFromGraveyard((opponentDrawFromGraveyard) =>
      "opponentDrawFromGraveyard" in data
        ? data.opponentDrawFromGraveyard
        : opponentDrawFromGraveyard
    )
    setOnDeathGlobals((onDeathGlobals) =>
      data.opponentDeathGlobals ? data.opponentDeathGlobals : onDeathGlobals
    )
    setOpponentDeathGlobals((opponentDeathGlobals) =>
      data.onDeathGlobals ? data.onDeathGlobals : opponentDeathGlobals
    )
    setOnCastGlobals((onCastGlobals) =>
      data.opponentCastGlobals ? data.opponentCastGlobals : onCastGlobals
    )
    setOpponentCastGlobals((opponentCastGlobals) =>
      data.onCastGlobals ? data.onCastGlobals : opponentCastGlobals
    )
    setOndrawGlobals((onDrawGlobals) =>
      data.opponentDrawGlobals ? data.opponentDrawGlobals : onDrawGlobals
    )
    setOpponentDrawGlobals((opponentDrawGlobals) =>
      data.onDrawGlobals ? data.onDrawGlobals : opponentDrawGlobals
    )
    setOnHealGlobals((onHealGlobals) =>
      data.opponentHealGlobals ? data.opponentHealGlobals : onHealGlobals
    )
    setOpponentHealGlobals((opponentHealGlobals) =>
      data.onHealGlobals ? data.onHealGlobals : opponentHealGlobals
    )
    setOnPlayGlobals((onPlayGlobals) =>
      data.opponentOnPlayGlobals ? data.opponentOnPlayGlobals : onPlayGlobals
    )
    setOpponentOnPlayGlobals((opponentOnPlayGlobals) =>
      data.onPlayGlobals ? data.onPlayGlobals : opponentOnPlayGlobals
    )
    setMyKingdomOfHeaven((myKingdomOfHeaven) =>
      data.opponentKingdomOfHeaven
        ? data.opponentKingdomOfHeaven
        : myKingdomOfHeaven
    )
    setMyKingdomOfHell((myKingdomOfHell) =>
      data.opponentKingdomOfHell ? data.opponentKingdomOfHell : myKingdomOfHell
    )
    setOpponentKingdomOfHeaven((opponentKingdomOfHeaven) =>
      data.myKingdomOfHeaven ? data.myKingdomOfHeaven : opponentKingdomOfHeaven
    )
    setOpponentKingdomOfHell((opponentKingdomOfHell) =>
      data.myKingdomOfHell ? data.myKingdomOfHell : opponentKingdomOfHell
    )
    setTheirAttacked((theirAttacked) =>
      data.attacked ? data.attacked : theirAttacked
    )
    setOpponentProphecyArray((opponentProphecyArray) =>
      data.prophecyArray ? data.prophecyArray : opponentProphecyArray
    )
    setMyCountersArray((myCountersArray) =>
      data.opponentsCountersArray
        ? data.opponentsCountersArray
        : myCountersArray
    )

    setMyExileZone((myExileZone) => 
      data.opponentExileZone ? data.opponentExileZone : myExileZone
    )

    setOpponentExileZone((opponentExileZone) => 
      data.myExileZone ? data.myExileZone : opponentExileZone
    )

    setOpponentsCountersArray((opponentsCountersArray) =>
      data.myCountersArray ? data.myCountersArray : opponentsCountersArray
    )

    setMyArtifacts((myArtifacts) => 
      data.opponentArtifacts ? data.opponentArtifacts : myArtifacts
    )

    setOpponentArtifacts((opponentArtifacts) => 
      data.myArtifacts ? data.myArtifacts : opponentArtifacts
    )

    setOpponentGyOrArtifacts(() => {
      if(data.myCurrentGY?.length !== theirGraveyard?.length) return true
      if(data.myArtifacts?.length !== opponentArtifacts?.length) return false
    })

    if (data.turnPass) {
      soundEffects.flipTurn()
      setIsTurn((isTurn) => !isTurn)
    }
    if (gameUsers.current.length) {
      gameUsers.current = data.users ? data.users : gameUsers.current
    }
  }

  function loadDeckGamePage(deckName) {
    const oldDeckInformation = deckList.find(
      (nameOfDeck) => deckName === nameOfDeck.name
    )
    if (!oldDeckInformation) return
    const newDeck = []
    const newSideBoard = []
    for (let i = 0; i < oldDeckInformation?.cardList.length; i++) {
      const card = cards.find(
        (cardNumber) => cardNumber.id === oldDeckInformation?.cardList[i]
      )
      newDeck.push({ ...card })
    }
    for (let i = 0; i < oldDeckInformation?.sideBoard.length; i++) {
      const card = cards.find(
        (cardNumber) => cardNumber.id === oldDeckInformation?.sideBoard[i]
      )
      newSideBoard.push({ ...card })
    }
    setDeck([...newDeck].sort((a, b) => a.cost - b.cost))
    setDeckName(oldDeckInformation.name)
    setSideBoard(newSideBoard)
  }

  const isolationCheck = (cards) => {
    const cardNamesSet = new Set()

    for (const card of cards) {
      if (cardNamesSet.has(card.name)) {
        // Duplicate card name found
        return false
      }
      cardNamesSet.add(card.name)
    }

    // All card names are unique
    return true
  }

  const handleDamageToArmor = (player, damage) => {
    player.durability &&
      (player.durability < damage
        ? (player.durability = 0)
        : (player.durability -= damage))
    player.durability === 0 && (player.block = 0)
    return player
  }

  function sendSpectatorBoard(roomData) {
    if (socket.current?.socketID === gameUsers.current[0]) {
      let board = {
        defenderCurrentBoard: [...theirBoard],
        attackerCurrentBoard: [...myBoard],
        theirCurrentGY: [...theirGraveyard],
        myCurrentGY: [...myGraveyard],
        myPlayer: { ...player },
        theirPlayer: { ...opponent },
        deck: [...gameDeck],
        myHand: [...hand],
        theirDeck: [...theirGameDeck],
        theirHand: [...opponentsHand],
        attacked: [...attacked],
        mana: mana,
        myCurrentMana: currentMana,
        opponentsMana: opponentsMana,
        opponentsCurrentMana: opponentsCurrentMana,
        gameUsers: gameUsers.current,
      }
      socket.current?.emit("board-current-send", { ...roomData, ...board })
    }
  }

  function boardAreaOfEffect(data, damage, player = "opponent", kill = false) {
    //ADD ADDITIONAL FUNCTIONS, ADD TYPE: STRING TO SELECT A AOE TYPE, RANDOM X TARGETS OR ALL TARGETS
    //ADD SINGLE TARGET FUNCTION THAT DOES SAME THING
    data.kill = kill
    const boards = {
      self: data.attackerCurrentBoard,
      opponent: data.defenderCurrentBoard,
    }
    const whoseBoard = {
      self: player === "self" || player === "both",
      opponent: player === "opponent" || player === "both",
    }
    for (let i = 0; i <= 1; i++) {
      const boardSide = i === 0 ? "self" : "opponent"
      if (whoseBoard[boardSide]) {
        for (let i = 0; i < boards[boardSide].length; i++) {
          kill
            ? (boards[boardSide][i].damageTaken += resolveDamage(
                data,
                boards[boardSide][i].defense,
                "dontKill",
                false,
                false,
                boards[boardSide][i]
              ))
            : (boards[boardSide][i].damageTaken += resolveDamage(
                data,
                damage,
                "dontKill",
                false,
                false,
                boards[boardSide][i]
              ))
        }

        for (let i = 0; i < boards[boardSide].length; ) {
          data.attackerKill = false
          data.defenderKill = false
          data = killCreature({
            ...data,
            location: boardSide === "self" ? "attacker" : "defender",
            card: boards[boardSide][i],
          })
          if (data.attackerKill || data.defenderKill) {
            data.attackerKill = false
            data.defenderKill = false
          } else {
            i++
          }
        }
      }
    }

    return data
  }

  function startSideBoard() {
    if (user?.usersettings?.automaticFullScreen) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(
          `Error attempting to enable full-screen mode: ${err.message}`
        )
      })
    }
    setGameDeck([...deck])
    setGameSideBoard([...sideBoard])
    socket.current.emit("deck-and-sideboard", {
      room: room.current,
      deck: [...deck],
      sideboard: [...sideBoard],
      user: user,
      ourName: user.userName,
      cardBack: user?.usersettings?.cardBack?.[deckName],
    })
  }

  const cancelSummon = () => {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    logGameAction(null, "cancelSummon")
    setIsTurn(true)
    setSpellCast(null)
  }

  const isHuman = (card) => {
    const allowedTypes = [
      "human",
      "demigod",
      "cultist",
      "shapeshifter",
      "nephilim",
      "vampire",
      "werewolf",
    ]

    if (myKingdomOfHell) {
      allowedTypes.push("demon")
    }
    if (myKingdomOfHeaven) {
      allowedTypes.push("angel")
    }

    return allowedTypes.some((type) => card.class.includes(type))
  }

  const isUndead = (card) => {
    const allowedTypes = ["undead", "vampire"]

    return allowedTypes.some((type) => card.class.includes(type))
  }

  const isDemon = (card) => {
    const allowedTypes = ["demon"]

    if (myKingdomOfHell) {
      allowedTypes.push("human")
      allowedTypes.push("demigod")
      allowedTypes.push("cultist")
      allowedTypes.push("shapeshifter")
      allowedTypes.push("nephilim")
    }

    return allowedTypes.some((type) => card.class.includes(type))
  }

  const handleProphecy = (data) => {
    data.prophecyArray = [
      ...data.prophecyArray,
      findCard(data, data.mySpellCast.id),
    ]
    return data
  }

  const isAngel = (card) => {
    const allowedTypes = [
      "angel",
      "archangel",
      "seraphim",
      "nephilim",
      "valkyrie",
    ]
    return allowedTypes.some((type) => card.class.includes(type))
  }

  const isAGod = (card) => {
    const allowedTypes = ["god", "titan", "demigod"]
    return allowedTypes.some((type) => card.class.includes(type))
  }

  const isAMonster = (card) => {
    const allowedTypes = ["monster", "shapeshifter", "giant", "werewolf"]
    return allowedTypes.some((type) => card.class.includes(type))
  }

  function mulliganSelection(card, index) {
    if (
      (socket.current.socketID === gameUsers.current[0] ||
        socket.current.socketID === gameUsers.current[1]) &&
      !sideBoarding
    ) {
      if (mulliganedCards.find((card) => card.index === index)) {
        setMulliganedCards(
          mulliganedCards.filter((card) => card.index !== index)
        )
      } else {
        setMulliganedCards([...mulliganedCards, { ...card, index: index }])
      }
    }
  }

  function sendMulligan() {
    if (!sideBoarding) {
      const oldHand = [...hand]
      const oldDeck = [...gameDeck]
      for (let i = 0; i < oldHand.length; i++) {
        if (mulliganedCards.map((card) => card.id).includes(oldHand[i].id)) {
          oldDeck.push(oldHand.splice(i, 1)[0])
          oldHand.push(oldDeck.splice(0, 1)[0])
          i--
        }
      }
      const shuffledDeck = []
      if (mulliganedCards.length) {
        while (oldDeck.length) {
          const randomIndex = Math.floor(Math.random() * oldDeck.length)
          shuffledDeck.push(oldDeck.splice(randomIndex, 1)[0])
        }
      }
      // setHand(oldHand)
      // setGameDeck(shuffledDeck.length ? shuffledDeck : oldDeck)
      // setResolveMulligan(false)
      if (
        socket.current.socketID === gameUsers.current[0] ||
        socket.current.socketID === gameUsers.current[1]
      ) {
        socket.current.emit(
          "update-board",
          {
            room: room.current,
            socketID: socket.current.socketID,
            myHand: oldHand,
            deck: shuffledDeck.length ? shuffledDeck : oldDeck,
            isMulligan: true,
            logs: [],
          },
          () => {
            setHand(oldHand)
            setGameDeck(shuffledDeck.length ? shuffledDeck : oldDeck)
            setResolveMulligan(false)
            setMulliganedCards([])
            soundEffects.cardShuffle()
          }
        )
      }
      // setMulliganedCards([])
      // soundEffects.cardShuffle()
    }
  }

  const removePairEffects = (card, board) => {
    const targetCard = board.find((creature) => creature.id === card.pairID)

    if (targetCard && targetCard.pairEffect) {
      targetCard.effects = targetCard.effects.filter(
        (effect) => effect !== targetCard.pairEffect.effects
      )
      targetCard.text = targetCard.text.replace(targetCard.pairEffect.text, "")
      delete targetCard.pairEffect
    }

    delete card.pairID
  }

  const processDeathGlobals = (data, globals) => {
    for (let i = 0; i < globals.length; i++) {
      data.OnDeathFunction = true
      const abilityIndex = globals[i].abilities[0]
      data.myFunction = spellFunctions[abilityIndex]
      data = resolveSpell(data, false)
      data.OnDeathFunction = false
    }
    return data
  }

  const addWorshipCreature = (card) => {
    if (
      socket.current.socketID === gameUsers.current[0] ||
      socket.current.socketID === gameUsers.current[1]
    ) {
      if (worshipArray.find((arrayCard) => arrayCard.id === card.id)) {
        setWorshipArray(
          worshipArray.filter((arrayCard) => arrayCard.id !== card.id)
        )
      } else {
        if (worshipArray.length < worship.cost) {
          setWorshipArray([...worshipArray, { ...card }])
        } else {
          const newWorshipArray = [...worshipArray]
          newWorshipArray.shift()
          newWorshipArray.unshift(card)
          setWorshipArray(newWorshipArray)
        }
      }
    }
  }

  function killCreature(data) {
    console.log(data.card)
    const isAttacker = data.location === "attacker"
    const card = isAttacker
      ? data.attackerCurrentBoard.find((card) => card.id === data.card.id)
      : data.defenderCurrentBoard.find((card) => card.id === data.card.id)
    const kill = data.kill || card?.damageTaken >= card?.defense
    const board = isAttacker
      ? data.attackerCurrentBoard
      : data.defenderCurrentBoard
    const graveyard = isAttacker ? data.myCurrentGY : data.theirCurrentGY
    const logs = data.logs

    if (kill && card) {
      data.startTurn = false
      if (data.sacrifice) {
        isAttacker
          ? (data.myPlayer.sacrifices += 1)
          : (data.theirPlayer.sacrifices += 1)
      }

      if (isAttacker) {
        data.defenderKill = true
        data.attackerSurvive = false
      } else {
        data.attackerKill = true
        data.defenderSurvive = false
      }

      const frozen = card.effects.includes("frozen")
      if (frozen) {
        logs.push(`${data.card.name} freeze is shattered`)
        data = resolveDamage(data, 3, isAttacker ? "self" : "opponent")
        card.effects.splice(card.effects.indexOf("frozen"), 1)
      }

      card.damageTaken = 0

      if (card.pairID) {
        removePairEffects(card, board)
      }

      const creatureIndex = board.findIndex(
        (creature) => creature.id === card.id
      )
      if (!card.token && !card.oneUse) {
        const [removedCreature] = board.splice(creatureIndex, 1)
        if ("elusive" in removedCreature) removedCreature.elusive = true
        graveyard.unshift(removedCreature)
        logs.push(`${removedCreature.name} has died`)
      } else {
        board.splice(creatureIndex, 1)
        logs.push(`${card.name} has been removed from the game`)
      }

      if (card.effects.includes("globalEffect")) {
        data = removeGlobals(data, "targetedGlobal", data.card)
      }

      data.location = "attacker"
      data = processDeathGlobals(data, data.onDeathGlobals)
      data.location = "defender"
      data = processDeathGlobals(data, data.opponentDeathGlobals)

      if (card.effects.includes("lastRites")) {
        const location = isAttacker ? "attacker" : "defender"
        data = resolveSpell(
          { ...data, myFunction: spellFunctions[card.abilities[0]], location, killCreatureFunction: true, },
          false
        )
      }

      if (card.sacrificialPawn && data.sacrifice) {
        const location = isAttacker ? "attacker" : "defender"
        data = resolveSpell(
          {
            ...data,
            myFunction: spellFunctions[card.sacrificialPawn],
            location,
          },
          false
        )
        data.sacrifice = false
      }
    }
    data.killCreatureFunction = false
    return data
  }

  // At the start of your turn sets all the variables needed for you to play
  function resolveTurnState(data) {
    if (playerSelection || worship.id) {
      handlePlayerSelection()
    } else {
      handleNonPlayerSelection(data)
    }
  }

  function handlePlayerSelection() {
    if (!discardSelection && !handSelection && !worship.id) {
      if (spellCast?.spellcaster) {
        playerChoiceIndex.current === 0
          ? chooseAttacker(spellCast, spellCast.index)
          : playerChoiceIndex.current === 1
          ? activateSpellCast()
          : cancelSpell()
      } else if (spellCast.activatesOn.includes("selfChoice")) {
        resolveSpell({
          myFunction:
            spellFunctions[spellCast.abilities[playerChoiceIndex.current]],
        })
      } else {
        resolveSpell({
          recursion: recursionTrue,
          myFunction: spellFunctions[spellCast.abilities[opponentChoice]],
        })
      }
      playerChoiceIndex.current = null
    }
    if (worship.id) {
      setWorship({})
      setWorshipArray([])
    }
    resetHandSelectionValues()
    resetDiscardChoices()
    setPlayerSelection(false)
  }

  function resetDiscardChoices() {
    setDiscardChoices([])
    setDiscardNumber(0)
    setDiscardSelection(false)
  }

  function resetHandSelectionValues() {
    setHandChoices([])
    setHandNumber(0)
    setHandSelection(false)
  }

  function handleNonPlayerSelection(data) {
    data = data ? data : setInitialState({})
    data.mana = mana + 1
    data.myCurrentMana = mana + 1
    const originalBoard = data.attackerCurrentBoard.map((card) => card.id)

    data = drawCards(data, 1)

    if (data.myPlayer.poison > 0) {
      data.myPlayer.damageTaken += data.myPlayer.poison
      data.myPlayer.poison -= 1
    }

    if (data.myPlayer.revitalize > 0) {
      data = calculateHealing(data, data.myPlayer.revitalize)
      data.myPlayer.revitalize -= 1
    }

    data = resolveProphecySpells(data)
    data = updateElusiveCreatures(data)
    data = resolveStartTurnSpells(data)
    data = resolveInGraveyardSpells(data)
    data = resolveCurses(data)

    const tappedCreatures = getTappedCreatures(data, originalBoard)
    updateBoardState({ ...data, tappedCreatures }, false, true)
  }

  function resolveProphecySpells(data) {
    for (let i = data.prophecyArray.length - 1; i >= 0; i--) {
      data.prophecyArray[i].prophecyCounter -= 1
      const card = findCard(data, data.prophecyArray[i].id)
      if (!card) {
        data.prophecyArray.splice(i, 1)
        continue
      }
      card.prophecyCounter -= 1

      if (
        data?.prophecyArray[i]?.prophecyCounter === 0 ||
        (data?.prophecyArray[i]?.prophecyActivation?.includes(
          data?.prophecyArray[i]?.prophecyCounter
        ) ??
          false)
      ) {
        data = resolveSpell(
          {
            ...data,
            myFunction: spellFunctions[data.prophecyArray[i].abilities[0]],
            prophecy: true,
            prophecyCard: data.prophecyArray[i],
          },
          false
        )

        data.prophecy = false
        if (data.prophecyArray[i].prophecyCounter === 0) {
          data.prophecyArray[i].prophecyCounter = data.prophecyArray[i].prophecy
          card.prophecyCounter = card.prophecy
          data.prophecyArray.splice(i, 1)
        }
      }
    }

    return data
  }

  function resolveCurses(data) {
    for(let i = 0; i < data.myHand.length; i++){
      if(data.myHand[i].class === 'curse'){
        data = resolveSpell(
          {
            ...data,
            myFunction: spellFunctions[data.myHand[i].abilities[0]],
            startTurn: true,
          },
          false
        )
      }
    }

    return data
  }

  function resolveStartTurnSpells(data) {
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if (data.attackerCurrentBoard[i].activatesOn.includes("startTurn")) {
        data = resolveSpell(
          {
            ...data,
            target: data.attackerCurrentBoard[i],
            index: i,
            myFunction:
              spellFunctions[data.attackerCurrentBoard[i].abilities[0]],
            startTurn: true,
            card: data.attackerCurrentBoard[i],
          },
          false
        )

        if (data?.buffCard) {
          data.card.attack += data.buffCard.attack
          data.card.defense += data.buffCard.defense
          delete data.buffCard
        }
      }
    }
    for(let i = 0; i < data.myArtifacts.length; i++){
      if(data.myArtifacts[i].activatesOn.includes("startTurn")){
        data = resolveSpell(
          {
            ...data,
            target: data.myArtifacts[i],
            index: i,
            myFunction:
              spellFunctions[data.myArtifacts[i].abilities[0]],
            startTurn: true,
            card: data.myArtifacts[i],
          },
          false
        )
      }
    }
    return data
  }

  function resolveInGraveyardSpells(data) {
    const activatedRealms = []
    for (let i = 0; i < data.myCurrentGY.length; i++) {
      if (data.myCurrentGY[i].activatesOn.includes("inGraveyard")) {
        if (activatedRealms.includes(data.myCurrentGY[i].id)) continue
        activatedRealms.push(data.myCurrentGY[i].id)

        data = resolveSpell(
          {
            ...data,
            target: data.myCurrentGY[i],
            index: i,
            myFunction: spellFunctions[data.myCurrentGY[i].abilities[0]],
            i: i,
          },
          false
        )
        i = data.i
      }
    }
    return data
  }

  function updateElusiveCreatures(data) {
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if ("elusive" in data.attackerCurrentBoard[i]) {
        data.attackerCurrentBoard[i].elusive = true
      }
    }
    return data
  }

  function getTappedCreatures(data, originalBoard) {
    const remainTappedArray = data.attackerCurrentBoard.filter((card) =>
      card.effects.includes("frozen")
    )
    const newCreatures = data.attackerCurrentBoard.filter(
      (card) =>
        !originalBoard.includes(card.id) && !card.effects.includes("haste")
    )

    return [...remainTappedArray, ...newCreatures]
  }

  const activateSpellCast = () => {
    let data = setInitialState({ creature: spellCast })

    data.attacked = [...data.attacked, spellCast]
    if (!data.creature.activatesOn.includes("noTarget")) {
      data.castingSpell = true
      data.spellCast = data.creature
    } else {
      data = resolveSpell({
        ...data,
        target: { ...data.creature },
        myFunction: spellFunctions[data.creature.abilities[0]],
      })
    }
  }

  const cancelSpell = (dontLog) => {
    if (!dontLog) logGameAction(null, "cancelSpell")
    if (callStack.length) {
      const theStack = [...callStack]
      const nextSpell = theStack.shift()
      if (
        nextSpell.name === "Unstable Magic" &&
        myGraveyard.find((card) => card.id === nextSpell.id)
      ) {
        setSpellCast(null)
        setCastingSpell(false)
      } else {
        setSpellCast(nextSpell)
        setCastingSpell(true)
        setCallStack(theStack)
      }
    } else {
      setSpellCast(null)
      setCastingSpell(false)
    }
  }

  // Joins the targeted game
  function joinGame(room, gameStarted) {
    const invalidDeck = {
      valid: true,
      invalidCards: [],
    }
    const deckCheck = reduceDeck([...deck, ...sideBoard])
    for (let i = 0; i < deckCheck.length; i++) {
      if (deckCheck[i].count > rarityIndex[deckCheck[i].rarity]) {
        invalidDeck.valid = false
        invalidDeck.invalidCards.push(deckCheck[i])
      }
    }
    if (
      (deck.length === 40 && invalidDeck.valid) ||
      process.env.NODE_ENV === "development"
    ) {
      if (socket.current) {
        socket.current.emit("join-game", {
          room: room,
          user: user,
        })
        if (!gameStarted) {
          socket.current.activeGame = true
        }
      }
    } else if (deck.length === 40 && !invalidDeck.valid) {
      setInfoText("Invalid Deck")
      setValidDeckCheck(invalidDeck.invalidCards)
    }
  }
  // Allows you to host a game
  const deckCheck = () => {
    const invalidDeck = {
      valid: true,
      invalidCards: [],
    }
    const deckCheck = reduceDeck([...deck, ...sideBoard])
    for (let i = 0; i < deckCheck.length; i++) {
      if (deckCheck[i].count > rarityIndex[deckCheck[i].rarity]) {
        invalidDeck.valid = false
        invalidDeck.invalidCards.push(deckCheck[i])
      }
    }
    return invalidDeck
  }

  function joinRankedMatchmaking() {
    const invalidDeck = deckCheck()
    if (
      deck.length === 40 &&
      (invalidDeck.valid || process.env.NODE_ENV === "development") &&
      signedIn &&
      socket.current.connected &&
      user.email !== "cardsofmythology@gmail.com"
    ) {
      if (socket.current) {
        socket.current.emit("join-ranked-game", {
          email: user.email,
          mmr: user.mmr,
          userName: user.userName,
        })
        socket.current.activeGame = true
        socket.current.inQueue = true
        setHideRooms(true)
        setInfoText("Looking for an opponent...")
        startTimer.current = true
      }
    } else if (user.email === "cardsofmythology@gmail.com") {
      setInfoText("Cannot queue for ranked while on the demo account")
    } else {
      setInfoText("Invalid Deck, Sign in, or Check your connection")
      setValidDeckCheck(invalidDeck.invalidCards)
    }
  }

  function joinNormalMatchmaking() {
    const invalidDeck = deckCheck()
    if (
      (deck.length === 40 &&
        invalidDeck.valid &&
        signedIn &&
        socket.current.connected &&
        user.email !== "cardsofmythology@gmail.com") ||
      process.env.NODE_ENV === "development"
    ) {
      if (socket.current) {
        socket.current.emit("join-normal-game", {
          email: user.email,
          userName: user.userName,
        })
        socket.current.activeGame = true
        socket.current.inQueue = true
        setHideRooms(true)
        setInfoText("Looking for an opponent...")
        startTimer.current = true
      }
    } else if (user.email === "cardsofmythology@gmail.com") {
      setInfoText("Cannot queue while on the demo account")
    } else {
      setInfoText("Invalid Deck, Sign in, or Check your connection")
      setValidDeckCheck(invalidDeck.invalidCards)
    }
  }

  // Passes the turn to the other player
  function passTurn(data) {
    if (animationStack.current.length > 0 || passTurnDisabled) {
      if (!animationStack.current.includes("passTurn"))
        animationStack.current.push("passTurn")
      return
    }
    if (!data) logGameAction(null, "passTurn")

    turnNumber.current += 1
    data = data ? data : setInitialState({})
    delete data.turnPass
    data.attackerCurrentBoard = data.attackerCurrentBoard.map((card) => {
      if (card.effects.includes("frozen")) {
        data = resolveDamage(data, 1, "self", false)
        card.effects.splice(card.effects.indexOf("frozen"), 1)
      }
      return card
    })
    for (let i = 0; i < data.attackerCurrentBoard.length; ) {
      if (data.attackerCurrentBoard[i]?.perishable) {
        data = data.killCreature({
          ...data,
          sacrifice: true,
          location: "attacker",
          card: data.attackerCurrentBoard[i],
          kill: true,
        })
      } else {
        i++
      }
    }
    const endTurnDuplicate = data.attackerCurrentBoard.filter((card) =>
      card.effects.includes("startTurnDuplicate")
    )
    for (let i = 0; i < endTurnDuplicate.length; i++) {
      data = resolveStartTurnSpells(data)
    }
    if (extraTurn) {
      // let tempMana = mana
      if (!dontResolveUpkeep) {
        resolveTurnState(data)
      } else {
        setDontResolveUpkeep(false)
      }
      // tempMana++
      // setCurrentMana(tempMana)
      // setMana(tempMana)
      setExtraTurn(false)
    } else {
      clickNumber.current = 0
      updateBoardState(data, null, null, true)
    }
  }

  function playCreatureFromGraveyard(target, index) {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    let data = setInitialState({ target: target, index: index })
    data.playCreatureFunction = true
    const resurrectedCreature = data.myCurrentGY.splice(
      data.myCurrentGY.findIndex((cardInGY) => target.id === cardInGY.id),
      1
    )[0]
    if (
      resurrectedCreature.abilities.length > 0 &&
      (resurrectedCreature.activatesOn.length === 0 ||
        resurrectedCreature.activatesOn.includes("friendly") ||
        resurrectedCreature.activatesOn.includes("myGraveyard"))
    ) {
      setCastingSpell(true)
      setSpellCast(resurrectedCreature)
    } else if (
      resurrectedCreature.abilities.length > 0 &&
      !resurrectedCreature.activatesOn.includes("onKill") &&
      !resurrectedCreature.activatesOn.includes("onBlock") &&
      !resurrectedCreature.activatesOn.includes("spellCast") &&
      !resurrectedCreature.activatesOn.includes("onAttack") &&
      !("veilOfShadows" in resurrectedCreature)
    ) {
      data = resolveSpell(
        {
          ...data,
          myFunction: spellFunctions[resurrectedCreature.abilities[0]],
        },
        false
      )
      setCastingSpell(false)
      setSpellCast(null)
    }
    if (!resurrectedCreature.effects.includes("haste")) {
      data.attacked.push(resurrectedCreature)
    } else {
      if (
        data.attacked.findIndex(
          (card) => card.id === resurrectedCreature.id
        ) !== -1
      ) {
        data.attacked.splice(
          data.attacked.findIndex((card) => card.id === resurrectedCreature.id),
          1
        )
      }
    }
    data.myCurrentMana -= Math.floor(resurrectedCreature.recursionCost)
    resurrectedCreature.recursionCost += 2
    resurrectedCreature.cost += 2

    data = processPlayGlobals(data, data.onPlayGlobals, "onPlayGlobalsFunction")
    data = processPlayGlobals(
      data,
      data.opponentOnPlayGlobals,
      "opponentOnPlayGlobalsFunction"
    )

    resurrectedCreature.attack = data?.buffCard
      ? resurrectedCreature.attack + data.buffCard.attack
      : resurrectedCreature.attack
    resurrectedCreature.defense = data?.buffCard
      ? resurrectedCreature.defense + data.buffCard.defense
      : resurrectedCreature.defense
    if (data?.buffCard) delete data.buffCard
    data.playCreatureFunction = false
    data.attackerCurrentBoard.push(resurrectedCreature)
    updateBoardState({ ...data, playedCreature: resurrectedCreature })
  }

  function calculateHealing(data, healAmount, player = "self") {
    if (player === "self") {
      if (
        data.onHealGlobals.find(
          (card) => card.name === "Sekhmet, Divine Healer"
        )
      ) {
        const additionalHealing = data.onHealGlobals.filter(
          (card) => card.name === "Sekhmet, Divine Healer"
        ).length
        healAmount = healAmount + additionalHealing * 3
      }

      if (
        data.onHealGlobals.find((card) => card.name === "Isis, Bringer Of Life")
      ) {
        const multiplier = data.onHealGlobals.filter(
          (card) => card.name === "Isis, Bringer Of Life"
        ).length
        for (let i = 0; i < multiplier; i++) {
          player === "self"
            ? (data.myPlayer.defense += healAmount)
            : (data.theirPlayer.defense += healAmount)
        }
      }
      if (data.myPlayer.healAbsorb > healAmount) {
        data.logs.push(`${healAmount} for ${data.myPlayer.name} is absorbed`)
        data.myPlayer.healAbsorb -= healAmount
      } else {
        healAmount -= data.myPlayer.healAbsorb
        data.logs.push(`${data.myPlayer.name} is healed for ${healAmount}`)
        data.myPlayer.healAbsorb = 0
        data.myPlayer.damageTaken > healAmount
          ? (data.myPlayer.damageTaken -= healAmount)
          : (data.myPlayer.damageTaken = 0)
      }
    } else {
      if (
        data.opponentHealGlobals.find(
          (card) => card.name === "Sekhmet, Divine Healer"
        )
      ) {
        const additionalHealing = data.opponentHealGlobals.filter(
          (card) => card.name === "Sekhmet, Divine Healer"
        ).length
        healAmount = healAmount + additionalHealing * 2
      }

      if (
        data.opponentHealGlobals.find(
          (card) => card.name === "Isis, Bringer Of Life"
        )
      ) {
        const multiplier = data.opponentHealGlobals.filter(
          (card) => card.name === "Isis, Bringer Of Life"
        ).length
        for (let i = 0; i < multiplier; i++) {
          healAmount = healAmount * 2
        }
      }
      if (data.theirPlayer.healAbsorb > healAmount) {
        data.logs.push(`${healAmount} for ${data.theirPlayer.name} is absorbed`)
        data.theirPlayer.healAbsorb -= healAmount
      } else {
        healAmount -= data.theirPlayer.healAbsorb
        data.logs.push(`${data.theirPlayer.name} is healed for ${healAmount}`)
        data.theirPlayer.healAbsorb = 0
        data.theirPlayer.damageTaken > healAmount
          ? (data.theirPlayer.damageTaken -= healAmount)
          : (data.theirPlayer.damageTaken = 0)
      }
    }

    for (let i = 0; i < data.onHealGlobals.length; i++) {
      data = spellFunctions[data.onHealGlobals[i].abilities[0]]({
        ...data,
        onHeal: true,
        healAmount: healAmount,
        heal: "myHeal",
        player: player,
      })
      data.onHeal = false
    }
    for (let i = 0; i < data.opponentHealGlobals.length; i++) {
      data = spellFunctions[data.opponentHealGlobals[i].abilities[0]]({
        ...data,
        onHeal: true,
        healAmount: healAmount,
        heal: "opponentHeal",
        player: player,
      })
      data.onHeal = false
    }

    return data
  }

  function playCreatureOnOpponentBoard(data) {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    if (data) {
      const playedCreature = { ...data.creature }
      if (
        data.creature.abilities.length > 0 &&
        data.castSpell &&
        (data.creature.activatesOn.length === 0 ||
          data.creature.activatesOn.includes("friendly") ||
          data.creature.activatesOn.includes("myGraveyard")) &&
        !data.creature.abilities.includes("doNotCast")
      ) {
        data.castingSpell = true
        data.spellCast = data.creature
      } else if (
        data.creature.abilities.length > 0 &&
        data.castSpell &&
        !data.creature.activatesOn.includes("onKill") &&
        !data.creature.activatesOn.includes("onBlock") &&
        !data.creature.activatesOn.includes("spellCast") &&
        !data.creature.abilities.includes("odinAllfather") &&
        !data.creature.activatesOn.includes("doNotCast")
      ) {
        data = resolveSpell(
          {
            ...data,
            target: { ...data.creature },
            myFunction: spellFunctions[data.creature.abilities[0]],
          },
          false
        )
        data.castingSpell = false
        data.spellCast = null
      }
      if (data.creature.effects.includes("haste")) {
        for (let i = 0; i < data.attacked.length; i++) {
          if (data.attacked[i].id === data.creature.id) {
            data.attacked.splice(i, 1)
          }
        }
      } else {
        data.theirAttacked = [...data.theirAttacked, data.creature]
      }
      data.defenderCurrentBoard = [...data.defenderCurrentBoard, playedCreature]
      if (data.creature.effects.includes("globalEffect")) {
        if (data.creature.effects.includes("onDeath")) {
          data.opponentDeathGlobals.push(data.creature)
        }
        if (data.creature.effects.includes("onCast")) {
          data.opponentCastGlobals.push(data.creature)
        }
        if (data.creature.effects.includes("onDraw")) {
          data.opponentDrawGlobals.push(data.creature)
        }
        if (data.creature.effects.includes("onHeal")) {
          data.opponentHealGlobals.push(data.creature)
        }

        data = removeGlobals(data, "creatureSwap", data.creature, "self")
      }

      return data
    }
  }

  function removeGlobals(data, resetType, target, side) {
    if (resetType === "allGlobals") {
      data.onDeathGlobals = []
      data.opponentDeathGlobals = []
      data.onCastGlobals = []
      data.opponentCastGlobals = []
      data.onDrawGlobals = []
      data.opponentDrawGlobals = []
      data.onHealGlobals = []
      data.opponentHealGlobals = []
    } else if (resetType === "creatureSwap") {
      if (side === "self") {
        data.onDeathGlobals = data.onDeathGlobals.filter(
          (card) => card.id !== target.id
        )
        data.onCastGlobals = data.onCastGlobals.filter(
          (card) => card.id !== target.id
        )
        data.onDrawGlobals = data.onDrawGlobals.filter(
          (card) => card.id !== target.id
        )
        data.onHealGlobals = data.onHealGlobals.filter(
          (card) => card.id !== target.id
        )
        data.onPlayGlobals = data.onPlayGlobals.filter(
          (card) => card.id !== target.id
        )
      } else {
        data.opponentDeathGlobals = data.opponentDeathGlobals.filter(
          (card) => card.id !== target.id
        )
        data.opponentCastGlobals = data.opponentCastGlobals.filter(
          (card) => card.id !== target.id
        )
        data.opponentDrawGlobals = data.opponentDrawGlobals.filter(
          (card) => card.id !== target.id
        )
        data.opponentHealGlobals = data.opponentHealGlobals.filter(
          (card) => card.id !== target.id
        )
        data.opponentOnPlayGlobals = data.opponentOnPlayGlobals.filter(
          (card) => card.id !== target.id
        )
      }
    } else if (resetType === "targetedGlobal") {
      data.opponentDeathGlobals = data.opponentDeathGlobals.filter(
        (card) => card.id !== target.id
      )
      data.opponentCastGlobals = data.opponentCastGlobals.filter(
        (card) => card.id !== target.id
      )
      data.opponentDrawGlobals = data.opponentDrawGlobals.filter(
        (card) => card.id !== target.id
      )
      data.opponentHealGlobals = data.opponentHealGlobals.filter(
        (card) => card.id !== target.id
      )
      data.opponentOnPlayGlobals = data.opponentOnPlayGlobals.filter(
        (card) => card.id !== target.id
      )
      data.onDeathGlobals = data.onDeathGlobals.filter(
        (card) => card.id !== target.id
      )
      data.onCastGlobals = data.onCastGlobals.filter(
        (card) => card.id !== target.id
      )
      data.onDrawGlobals = data.onDrawGlobals.filter(
        (card) => card.id !== target.id
      )
      data.onHealGlobals = data.onHealGlobals.filter(
        (card) => card.id !== target.id
      )
      data.onPlayGlobals = data.onPlayGlobals.filter(
        (card) => card.id !== target.id
      )
    }

    return data
  }

  function creatureSelfChoice(data, card) {
    if (card.activatesOn.includes("selfChoice")) {
      setIsTurn(false)
      setPlayerSelection(true)
      setPlayerSelectionOptions(card.choiceText)
      data.spellCast = card
    }
    return data
  }
  function processPlayGlobals(data, globals, location) {
    for (let i = 0; i < globals.length; i++) {
      data[location] = true
      const abilityIndex = globals[i].abilities[0]
      data.myFunction = spellFunctions[abilityIndex]
      data = resolveSpell(data, false)
      data[location] = false
    }
    return data
  }

  function addGlobalEffect (data, card) {
    const effectMapping = {
      onDeath: "onDeathGlobals",
      onCast: "onCastGlobals",
      onDraw: "onDrawGlobals",
      onHeal: "onHealGlobals",
      onPlay: "onPlayGlobals",
    }

    for (const effect of card.effects) {
      if (effectMapping[effect]) {
        data[effectMapping[effect]].push(card)
      }
    }

    data = removeGlobals(data, "creatureSwap", card, "opponent")

    return data
  }

  // Plays your clicked on creature card
  function playCreature(
    data,
    card,
    playedFromHand = false,
    worshipSummon = false
  ) {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    if (worshipSummon) logGameAction(card, "worshipSummon")
    if (data) {
      setCardCasting(data.creature)
      const playedCreature = { ...data.creature }
      data.playCreatureFunction = true
      data.card = data.creature

      if (playedCreature.effects.includes("globalEffect")) {
        data = addGlobalEffect(data, playedCreature)
      }

      if (data.myKingdomOfHell) {
        if (isHuman(data.card)) {
          data.card.class = "demon"
        }
        if (isDemon(playedCreature)) {
          data = calculateHealing(data, 1)
          data = resolveDamage(data, 1, "opponent", false)
        }
      }

      if (
        playedCreature.abilities.length > 0 &&
        data.castSpell &&
        (playedCreature.activatesOn.length === 0 ||
          playedCreature.activatesOn.includes("friendly") ||
          playedCreature.activatesOn.includes("myGraveyard")) &&
        !playedCreature.abilities.includes("doNotCast") &&
        (!playedCreature.effects.includes("manachase") ||
          (playedCreature.effects.includes("manachase") &&
            mana <= opponentsMana))
      ) {
        data = handleCallStack(data, playedCreature)
      } else if (
        playedCreature.abilities.length > 0 &&
        data.castSpell &&
        !playedCreature.activatesOn.includes("onKill") &&
        !playedCreature.activatesOn.includes("onBlock") &&
        !playedCreature.activatesOn.includes("spellCast") &&
        !playedCreature.abilities.includes("odinAllfather") &&
        !playedCreature.activatesOn.includes("doNotCast") &&
        !playedCreature.activatesOn.includes("onAttack") &&
        (!playedCreature.effects.includes("manachase") ||
          (playedCreature.effects.includes("manachase") &&
            mana <= opponentsMana))
      ) {
        data = resolveSpell(
          {
            ...data,
            target: { ...playedCreature },
            myFunction: spellFunctions[playedCreature.abilities[0]],
          },
          false
        )
      }
      if (playedCreature.effects.includes("haste")) {
        for (let i = 0; i < data.attacked.length; i++) {
          if (data.attacked[i].id === playedCreature.id) {
            data.attacked.splice(i, 1)
          }
        }
      } else {
        data.attacked = [...data.attacked, playedCreature]
      }

      data = processPlayGlobals(
        data,
        data.onPlayGlobals,
        "onPlayGlobalsFunction"
      )
      data = processPlayGlobals(
        data,
        data.opponentOnPlayGlobals,
        "opponentOnPlayGlobalsFunction"
      )

      playedCreature.attack = data?.buffCard
        ? playedCreature.attack + data.buffCard.attack
        : playedCreature.attack
      playedCreature.defense = data?.buffCard
        ? playedCreature.defense + data.buffCard.defense
        : playedCreature.defense
      if (data?.buffCard) delete data.buffCard

      if (!data?.dontPlayCreature) {
        data.attackerCurrentBoard.push(playedCreature)
      }

      data.playCreatureFunction = false
      return data
    } else {
      setCardCasting(card)
      data = setInitialState({})
      data.playCreatureFunction = true
      data.myHand.splice(
        data.myHand.findIndex((handCard) => handCard.id === card.id),
        1
      )
      data.card = card

      if (!worshipSummon) {
        if (data.creatureReduction.reducedCost) {
          if (data.creatureReduction.lifeCost.payLife) {
            if (data.creatureReduction.lifeCost.amount === 10000) {
              data = data.resolveDamage(data, card.cost, "self")
            } else {
            }
          }
          data.creatureReduction.reset = true
        } else if (card.costReduction) {
          const qualifyingCreatures = data.attackerCurrentBoard.filter(creature => creature.cost <= 2);
        
          // Calculate total reduction
          const totalReduction = card.costReduction * qualifyingCreatures.length;

          data.myCurrentMana = Math.max(0, data.myCurrentMana - (card.cost - totalReduction))
        } else {
          data.myCurrentMana -= card.cost
        }
      }

      if (card.effects.includes("globalEffect")) {
        const effectMapping = {
          onDeath: "onDeathGlobals",
          onCast: "onCastGlobals",
          onDraw: "onDrawGlobals",
          onHeal: "onHealGlobals",
          onPlay: "onPlayGlobals",
        }

        for (const effect of card.effects) {
          if (effectMapping[effect]) {
            data[effectMapping[effect]].push(card)
          }
        }
      }

      if (data.card.effects.includes("hindered") && playedFromHand) {
        data.card.damageTaken += data.card.hindered
      }

      if (data.myKingdomOfHell) {
        if (isHuman(data.card)) {
          data.card.class = "demon"
        }
        if (isDemon(data.card)) {
          data = calculateHealing(data, 1)
          data = resolveDamage(data, 1, "opponent", false)
        }
      }

      if (playedFromHand) {
        data.playedFromHand = true
      }
      data = creatureSelfChoice(data, card)

      if (
        card.abilities.length > 0 &&
        card.activatesOn.includes("noTarget") &&
        !card.activatesOn.includes("startTurnOnly") &&
        !card.activatesOn.includes("onBlock") &&
        !card.activatesOn.includes("spellCast") &&
        !card.activatesOn.includes("doNotCast") &&
        (!card.effects.includes("manachase") ||
          (card.effects.includes("manachase") && mana <= opponentsMana))
      ) {
        data = resolveSpell(
          {
            ...data,
            creature: card,
            myFunction: spellFunctions[card.abilities[0]],
          },
          false
        )
      } else if (
        card.abilities.length > 0 &&
        (card.activatesOn.length === 0 ||
          card.activatesOn.includes("friendly") ||
          (card.activatesOn.includes("myGraveyard") &&
            !card.activatesOn.includes("spellCast"))) &&
        !card.abilities.includes("doNotCast") &&
        (!card.effects.includes("manachase") ||
          (card.effects.includes("manachase") && mana <= opponentsMana))
      ) {
        data = handleCallStack(data, card)
      }

      if (!card.effects.includes("haste")) {
        data.attacked.push(card)
      }

      data = processPlayGlobals(
        data,
        data.onPlayGlobals,
        "onPlayGlobalsFunction"
      )
      data = processPlayGlobals(
        data,
        data.opponentOnPlayGlobals,
        "opponentOnPlayGlobalsFunction"
      )

      card.attack = data?.buffCard
        ? card.attack + data.buffCard.attack
        : card.attack
      card.defense = data?.buffCard
        ? card.defense + data.buffCard.defense
        : card.defense
      if (data?.buffCard) delete data.buffCard

      if (!data.dontPlayCreature) {
        data.attackerCurrentBoard.push(card)
      }

      data.playCreatureFunction = false

      if (worshipSummon) {
        data.myCurrentMana -= card.cost - worshipArray.length
        for (let i = 0; i < worshipArray.length; i++) {
          data = killCreature({
            ...data,
            sacrifice: true,
            location: "attacker",
            card: worshipArray[i],
            kill: true,
          })
        }
        setIsTurn(true)
      }

      updateBoardState({ ...data, playedFromHand: playedFromHand })
    }
  }

  // Sets a creature to be your next attacker
  function chooseAttacker(card, index) {
    if (isTurn && !attacked.map((card) => card.id).includes(card.id)) {
      if (card.attack > 0 && !card.effects.includes("cantAttack")) {
        setAttacker({ ...card, index })
      }
    }
    cancelSpell("dontLog")
  }

  // Test if card played is a realm

  const isARealm = (card, location) => {
    return (
      location === "hand" &&
      isTurn &&
      card.cost <= currentMana &&
      card.activatesOn.includes("inGraveyard")
    )
  }

  //Test if opponent has to make a choice

  const isOpponentChooses = (card, location) => {
    return (
      location === "hand" &&
      card.activatesOn.includes("opponentChooses") &&
      card.cost <= currentMana &&
      !spellCast
    )
  }

  const selfChoice = (card, location) => {
    return (
      location === "hand" &&
      card.activatesOn.includes("selfChoice") &&
      card.cost <= currentMana &&
      !spellCast &&
      card.type === "spell"
    )
  }

  // Handles what happens when any card is clicked on
  function clickLocation(card, index, location) {
    if (passTurnDisabled) return
    logGameAction(
      { ...card, index: index, location: location },
      "clickLocation"
    )
    if (!isTurn) {
      handleNonTurnClick(card, location)
    } else {
      if (isARealm(card, location)) {
        setAttacker(null)
        setCastingSpell(true)
        setSpellCast({ ...card, index })
        setSpellID(card.id)
        if (spellID === card.id) {
          realmCard(card, index)
        }
      } else if (isOpponentChooses(card, location)) {
        setAttacker(null)
        if (card.type === "spell") {
          setSpellID(card.id)
        }
        setCastingSpell(true)
        setSpellCast({ ...card, index })
      } else if (selfChoice(card, location)) {
        setAttacker(null)
        if (card.type === "spell") {
          setSpellID(card.id)
        }
        setCastingSpell(true)
        setSpellCast({ ...card, index })
      } else if (location === "hand") {
        setAttacker(null)
        handleHandClick(card)
      } else if (location === "theirHand") {
      } else if (location === "theirBoard") {
        receiveDamage(card, index)
      } else if (location === "theirGraveyard") {
        setAttacker(null)
        handleTheirGraveyardClick(card, index)
      } else if (location === "myBoard") {
        handleBoardClick(card, index)
      } else if (location === "myGraveyard") {
        setAttacker(null)
        handleMyGraveyardClick(card, index)
      } else if (location === "myArtifacts"){
        setExpandMyArtifacts((expandMyArtifacts) => !expandMyArtifacts)
      } else if (location === "theirArtifacts"){
        handleTheirArtifactClick(card)
      }
    }
  }

  function meltArtifact(data, card, maximumCost){
    if(card?.cost <= maximumCost){
      data.theirCurrentGY = [...data.opponentArtifacts.splice(data.opponentArtifacts.findIndex(artifact => artifact.id === card.id),1), ...data.theirCurrentGY]
      data = removeGlobals(data, "targetedGlobal", card)
    }

    return data
  }

  function handleTheirArtifactClick(card){
    if(!expandOpponentArtifacts){
      setExpandOpponentArtifacts((expandOpponentArtifacts) => !expandOpponentArtifacts)
    }else if(spellCast){
      resolveSpell({target: card, myFunction: spellFunctions[spellCast.abilities[0]]}, true)
    }else{
      setExpandOpponentArtifacts((expandOpponentArtifacts) => !expandOpponentArtifacts)
    }
  }

  //Breakdown of click card function
  function handleNonTurnClick(card, location) {
    if (location === "hand") {
      handleNonTurnHandClick(card)
    } else if (location === "theirGraveyard") {
      setExpandOpponentGY((expandOpponentGY) => !expandOpponentGY)
    } else if (location === "myGraveyard") {
      setExpandMyGY((expandMyGY) => !expandMyGY)
    } else if (location === "myBoard" && worship.id) {
      addWorshipCreature(card)
    } else if (location === "myArtifacts"){
      setExpandMyArtifacts((expandMyArtifacts) => !expandMyArtifacts)
    } else if (location === "theirArtifacts"){
      setExpandOpponentArtifacts((expandOpponentArtifacts) => !expandOpponentArtifacts)
    }
  }

  function handleNonTurnHandClick(card) {
    if (discardSelection) {
      chooseDiscardCards(card)
    } else if (handSelection) {
      chooseHandSelectCards(card)
    }
  }

  function chooseHandSelectCards(card) {
    if (handChoices.find((cardInArray) => cardInArray.id === card.id)) {
      setHandChoices(
        handChoices.filter((cardInArray) => cardInArray.id !== card.id)
      )
    } else {
      setHandChoices([...handChoices, card])
    }
  }

  function chooseDiscardCards(card) {
    if (discardChoices.find((cardInArray) => cardInArray.id === card.id)) {
      setDiscardChoices(
        discardChoices.filter((cardInArray) => cardInArray.id !== card.id)
      )
    } else {
      setDiscardChoices([...discardChoices, card])
    }
  }

  function handleMyGraveyardClick(card, index) {
    if (!expandMyGY) {
      setExpandMyGY(true)
    } else if (spellCast && card.name !== "Bargain with Hel") {
      resolveSpell({
        target: card,
        index,
        myFunction: spellFunctions[spellCast.abilities[0]],
        handleMyGraveyard: true
      })
    } else if (expandMyGY && card.activatesOn.includes("recursion")) {
      if (
        card.type === "spell" &&
        spellID !== card.id &&
        card.recursionCost <= currentMana
      ) {
        setSpellID(card.id)
        setCastingSpell(true)
        setSpellCast({ ...card, index })
      } else if (
        card.type === "spell" &&
        spellID === card.id &&
        card.activatesOn.includes("noTarget")
      ) {
        resolveSpell({
          target: card,
          recursion: true,
          myFunction: spellFunctions[spellCast.abilities[0]],
        })
      } else if (
        card.type === "spell" &&
        spellID === card.id &&
        card.activatesOn.includes("opponentChooses")
      ) {
        setIsTurn(false)
        setPlayerSelection(true)
        socket.current.emit("player-choice", {
          room: room.current,
          socketID: socket.current.socketID,
          options: card.choiceText,
          recursion: true,
        })
      }
    } else if (
      castingSpell &&
      expandMyGY &&
      spellCast?.activatesOn.includes("myGraveyard")
    ) {
      castOnGY(card, index)
    } else if (
      (card.type === "creature" || card.type === "spellcaster") &&
      card.recursionCost <= currentMana &&
      card.activatesOn.includes("resurrection")
    ) {
      playCreatureFromGraveyard(card, index)
    } else if (expandMyGY) {
      setExpandMyGY(false)
    }
  }

  function handleBoardClick(card, index) {
    if (spellCast?.activatesOn.includes("friendly") && !card?.spellImmune) {
      resolveSpell({
        target: card,
        index,
        myFunction: spellFunctions[spellCast.abilities[0]],
      })
    } else {
      if (
        card.activatesOn.includes("spellCast") &&
        !attacked.map((card) => card.id).includes(card.id)
      ) {
        setIsTurn(false)
        setPlayerSelection(true)
        setSpellCast({ ...card, index, spellcaster: true })
        setCastingSpell(true)
        setPlayerSelectionOptions(["Attack", "Activate ability", "Cancel"])
      } else {
        setCastingSpell(false)
        setSpellCast(null)
        chooseAttacker(card, index)
      }
    }
  }

  function canCast(card) {
    if (spellReduction.reducedCost && card.type === "spell") {
      if (
        spellReduction.reduceCost === 10000 ||
        spellReduction.lifeCost.amount === 10000
      ) {
        return true
      } else if (
        card.cost <= currentMana + spellReduction.reduceCost ||
        card.cost <= currentMana + spellReduction.lifeCost.amount
      ) {
        return true
      }
    } else {
      if (
        creatureReduction.reduceCost === 10000 ||
        creatureReduction.lifeCost.amount === 10000
      ) {
        return true
      } else if (card.costReduction) {
         // Count the number of creatures with cost 2 or less
         const qualifyingCreatures = myBoard.filter(creature => creature.cost <= 2);
        
         // Calculate total reduction
         const totalReduction = 2 * qualifyingCreatures.length;
         
         return card.cost - totalReduction <= currentMana
      } else if (
        card.cost <= currentMana + creatureReduction.reduceCost ||
        card.cost <= currentMana + creatureReduction.lifeCost.amount
      ) {
        return true
      }
      return card.cost <= currentMana
    }
  }

  const availableWorshipMana = () => {
    return myBoard.length + currentMana
  }

  const setUpFirstCardClick = (card) => {
    if (!canCast(card)) return
    if (card.type === "spell" || card.type === "artifact") {
      setSpellID(card.id)
      setCastingSpell(true)
      setSpellCast(card)
    }
  }

  const handleWorship = (card) => {
    if (spellCast) {
      setSpellCast(null)
      return
    }
    if (availableWorshipMana() >= card.cost && myBoard.length > 0) {
      setIsTurn(false)
      setWorship(card)
    } else if (canCast(card)) {
      setCardCasting(card)
      playCreature(null, card, true)
    }
  }

  const handleOpponentChooses = (card) => {
    if (spellCast.id !== card.id) {
      setSpellCast(card)
      return
    }
    setIsTurn(false)
    setPlayerSelection(true)
    setOpponentChoosing(true)
    socket.current.emit("player-choice", {
      room: room.current,
      socketID: socket.current.socketID,
      options: card.choiceText,
    })
  }

  const handleSelfChoice = (card) => {
    if (
      spellCast.id !== card.id &&
      (card.type !== "creature" || card.type !== "spellcaster")
    ) {
      setSpellCast(card)
      return
    }
    setIsTurn(false)
    setPlayerSelection(true)
    if (card.choiceText) {
      setPlayerSelectionOptions(card.choiceText)
    }
  }

  const playArtifact = (card) => {
   let data = setInitialState({})
   if(card.effects.includes("globalEffect")){
    addGlobalEffect(data, card)
   }
   if(card.activatesOn.includes("noTarget")){
    data = resolveSpell({...data, myFunction: spellFunctions[card.abilities[0]]}, false)
   }

   data = artifactCost(data)

   data.myArtifacts = [...data.myHand.splice(data.myHand.findIndex((card) => card.id === data.mySpellCast.id), 1), ...data.myArtifacts]

   updateBoardState(data)
  }

  const secondCardClick = (card) => {
    card.effects.includes("worship") && handleWorship(card)

    if (!canCast(card)) return

    if (
      (card.activatesOn.includes("noTarget") &&
        card.type === "spell" &&
        spellCast.id === card.id) ||
      card.activatesOn.includes("opponentChooses") ||
      card.activatesOn.includes("opponentChooses")
    ) {
      setCardCasting(card)
    }

    !card.effects.includes("worship") &&
      (card.type === "creature" || card.type === "spellcaster") &&
      !spellCast &&
      playCreature(null, card, true)

    !card.effects.includes("worship") &&
      (card.type === "creature" || card.type === "spellcaster") &&
      spellCast &&
      setSpellCast(null)

    card.activatesOn.includes("opponentChooses") && handleOpponentChooses(card)

    card.activatesOn.includes("selfChoice") && handleSelfChoice(card)

    card.activatesOn.includes("noTarget") &&
      card.type === "spell" &&
      spellCast.id === card.id &&
      resolveSpell({
        myFunction: spellFunctions[spellCast.abilities[0]],
        card: card,
      })

    card.activatesOn.includes("noTarget") &&
      card.type === "spell" &&
      spellCast.id !== card.id &&
      setSpellCast(card)

    card.type === "artifact" && playArtifact(card)
  }

  function handleHandClick(card) {
    if (!spellCast && card.type !== "creature" && card.type !== "spellcaster")
      setUpFirstCardClick(card)
    if (spellCast || card.type === "creature" || card.type === "spellcaster")
      secondCardClick(card)
  }

  function setCardCasting(card) {
    if (card) card.casting = true
  }

  function handleTheirGraveyardClick(card, index) {
    if (!expandOpponentGY) {
      setExpandOpponentGY((expandOpponentGY) => !expandOpponentGY)
    } else if (spellCast?.activatesOn.includes("theirGraveyard")) {
      resolveSpell({
        target: card,
        index,
        myFunction: spellFunctions[spellCast.abilities[0]],
      })
    } else if (expandOpponentGY) {
      setExpandOpponentGY((expandOpponentGY) => !expandOpponentGY)
    }
  }

  function setInitialState(data) {
    return {
      myCountersArray: JSON.parse(JSON.stringify([...myCountersArray])),
      opponentsCountersArray: JSON.parse(
        JSON.stringify([...opponentsCountersArray])
      ),
      myKingdomOfHeaven: data.myKingdomOfHeaven
        ? data.myKingdomOfHeaven
        : myKingdomOfHeaven,
      opponentKingdomOfHeaven: data.opponentKingdomOfHeaven
        ? data.opponentKingdomOfHeaven
        : opponentKingdomOfHeaven,
      myKingdomOfHell: data.myKingdomOfHell
        ? data.myKingdomOfHell
        : myKingdomOfHell,
      opponentKingdomOfHell: data.opponentKingdomOfHell
        ? data.opponentKingdomOfHell
        : opponentKingdomOfHell,
      myArtifacts: data?.myArtifacts ? data.myArtifacts : myArtifacts,
      opponentArtifacts: data?.opponentArtifacts ? data.opponentArtifacts : opponentArtifacts,
      playerAbility: data.playerAbility ? data.playerAbility : false,
      defenderCurrentBoard: data.defenderCurrentBoard
        ? JSON.parse(JSON.stringify([...data.defenderCurrentBoard]))
        : JSON.parse(JSON.stringify([...theirBoard])),
      attackerCurrentBoard: data.attackerCurrentBoard
        ? JSON.parse(JSON.stringify([...data.attackerCurrentBoard]))
        : JSON.parse(JSON.stringify([...myBoard])),
      myHand: data.myHand
        ? JSON.parse(JSON.stringify([...data.myHand]))
        : JSON.parse(JSON.stringify([...hand])),
      theirCurrentGY: data.theirCurrentGY
        ? JSON.parse(JSON.stringify([...data.theirCurrentGY]))
        : JSON.parse(JSON.stringify([...theirGraveyard])),
      nextCastDamage: JSON.parse(JSON.stringify({ ...nextCastDamage })),
      doubleCast: JSON.parse(JSON.stringify({ ...doubleCast })),
      myCurrentGY: data.myCurrentGY
        ? JSON.parse(JSON.stringify([...data.myCurrentGY]))
        : JSON.parse(JSON.stringify([...myGraveyard])),
      target: data.target
        ? JSON.parse(JSON.stringify({ ...data.target }))
        : null,
      index: data.index,
      creature: data.creature ? data.creature : null,
      myPlayer: data.myPlayer
        ? JSON.parse(JSON.stringify({ ...data.myPlayer }))
        : JSON.parse(JSON.stringify({ ...player })),
      theirPlayer: data.theirPlayer
        ? JSON.parse(JSON.stringify({ ...data.theirPlayer }))
        : JSON.parse(JSON.stringify({ ...opponent })),
      mySpellCast: data.mySpellCast ? data.mySpellCast : spellCast,
      spellCast: data.spellCast ? data.spellCast : spellCast ? null : spellCast,
      deck: data.deck
        ? JSON.parse(JSON.stringify([...data.deck]))
        : JSON.parse(JSON.stringify([...gameDeck])),
      theirDeck: data.theirDeck
        ? data.theirDeck
        : JSON.parse(JSON.stringify([...theirGameDeck])),
      theirHand: data.theirHand
        ? data.theirHand
        : JSON.parse(JSON.stringify([...opponentsHand])),
      attacked: data.attacked
        ? data.attacked
        : JSON.parse(JSON.stringify([...attacked])),
      logs: [],
      theirAttacked: data.theirAttacked
        ? data.theirAttacked
        : JSON.parse(JSON.stringify([...theirAttacked])),
      spellReduction: data.spellReduction
        ? data.spellReduction
        : JSON.parse(JSON.stringify({ ...spellReduction })),
      creatureReduction: data.creatureReduction
        ? data.creatureReduction
        : JSON.parse(JSON.stringify({ ...creatureReduction })),
      callStack: data.callStack
        ? data.callStack
        : JSON.parse(JSON.stringify([...callStack])),
      resolveSpell: resolveSpell,
      resolveDamage: resolveDamage,
      drawCards: drawCards,
      spellSplice: spellSplice,
      calculateHealing: calculateHealing,
      killCreature: killCreature,
      playCreature: playCreature,
      playCreatureOnOpponentBoard: playCreatureOnOpponentBoard,
      randomId: randomId,
      discardCards: discardCards,
      removeGlobals: removeGlobals,
      handleCallStack: handleCallStack,
      isHuman: isHuman,
      isAGod: isAGod,
      isAMonster: isAMonster,
      isAngel: isAngel,
      isDemon: isDemon,
      isUndead: isUndead,
      handleProphecy: handleProphecy,
      findCard: findCard,
      boardAreaOfEffect: boardAreaOfEffect,
      handleDamageToArmor: handleDamageToArmor,
      isolationCheck: isolationCheck,
      meltArtifact: meltArtifact,
      myExileZone: data?.myExileZone ? data.myExileZone : [...myExileZone],
      opponentExileZone: data?.opponentExileZone ? data.opponentExileZone : [...opponentExileZone],
      myFunction: data.myFunction ? data.myFunction : null,
      castingSpell: data.castingSpell
        ? data.castingSpell
        : castingSpell
        ? false
        : castingSpell,
      side: data.side ? data.side : null,
      mana: "mana" in data ? data.mana : mana,
      opponentsMana:
        "opponentsMana" in data ? data.opponentsMana : opponentsMana,
      myCurrentMana: "myCurrentMana" in data ? data.myCurrentMana : currentMana,
      didAttackOccur: data.didAttackOccur ? true : false,
      attacker: data.attacker ? data.attacker : attacker,
      drawFromGraveyard: drawFromGraveyard,
      opponentDrawFromGraveyard: opponentDrawFromGraveyard,
      recursion: data.recursion ? data.recursion : false,
      usedPlayerAbility: usedPlayerAbility,
      card: data.card ? data.card : null,
      startTurn: data.startTurn ? true : false,
      location: data.location ? data.location : "attacker",
      defenderKill: data.defenderKill ? data.defenderKill : false,
      defenderSurvive: data.defenderSurvive ? data.defenderSurvive : true,
      attackerKill: data.attackerKill ? data.attackerKill : false,
      attackerSurvive: data.attackerSurvive ? data.attackerSurvive : true,
      playedCreature: data.playedCreature ? data.playedCreature : false,
      onDeathGlobals: data.onDeathGlobals
        ? data.onDeathGlobals
        : JSON.parse(JSON.stringify([...onDeathGlobals])),
      opponentDeathGlobals: data.opponentDeathGlobals
        ? data.opponentDeathGlobals
        : JSON.parse(JSON.stringify([...opponentDeathGlobals])),
      onCastGlobals: data.onCastGlobals
        ? data.onCastGlobals
        : JSON.parse(JSON.stringify([...onCastGlobals])),
      opponentCastGlobals: data.opponentCastGlobals
        ? data.opponentCastGlobals
        : JSON.parse(JSON.stringify([...opponentCastGlobals])),
      onDrawGlobals: data.onDrawGlobals
        ? data.onDrawGlobals
        : JSON.parse(JSON.stringify([...onDrawGlobals])),
      opponentDrawGlobals: data.opponentDrawGlobals
        ? data.opponentDrawGlobals
        : JSON.parse(JSON.stringify([...opponentDrawGlobals])),
      onHealGlobals: data.onHealGlobals
        ? data.onHealGlobals
        : JSON.parse(JSON.stringify([...onHealGlobals])),
      opponentHealGlobals: data.opponentHealGlobals
        ? data.opponentHealGlobals
        : JSON.parse(JSON.stringify([...opponentHealGlobals])),
      onPlayGlobals: data.onPlayGlobals
        ? data.onPlayGlobals
        : JSON.parse(JSON.stringify([...onPlayGlobals])),
      opponentOnPlayGlobals: data.opponentOnPlayGlobals
        ? data.opponentOnPlayGlobals
        : JSON.parse(JSON.stringify([...opponentOnPlayGlobals])),
      sacrifice: data.sacrifice ? data.sacrifice : false,
      castAbility: data.castAbility ? data.castAbility : false,
      handNumber: data.handNumber ? data.handNumber : handNumber,
      handChoices: data.handChoices
        ? JSON.parse(JSON.stringify([...data.handChoices]))
        : JSON.parse(JSON.stringify([...handChoices])),
      handSelection: data.handSelection ? data.handSelection : handSelection,
      prophecyArray: JSON.parse(JSON.stringify([...prophecyArray])),
      opponentProphecyArray: JSON.parse(
        JSON.stringify([...opponentProphecyArray])
      ),
      myVoid: [...theVoid],
      theirVoid: [...theirVoid],
      handleMyGraveyard: data?.handleMyGraveyard ? data.handleMyGraveyard : false,
      startDeck: [...statTrackingDeck]
    }
  }

  // Resolves spell casts when you choose to play a spell
  function resolveSpell(data, topLevel = true) {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return

    if (topLevel) data = setInitialState(data)

    setCardCasting(card)
    if (spellIsCountered(data)) return counterSpell(data, topLevel)

    return castSpell(data, topLevel)
  }

  function counterSpell(data, topLevel) {
    data = spellFunctions[data.myCountersArray[0].abilities[0]]({
      ...data,
      counterSpellFunction: true,
    })
    data.myCountersArray.splice(0, 1)
    if (topLevel) {
      updateBoardState(data, true)
    } else {
      return data
    }
  }

  function updateBoardState(
    data,
    counterSpell,
    resolveTurnState,
    turnPass = false,
    startGame = false
  ) {
    if (!data.turnPass) {
      // setLogs((logs) => [...logs, ...data.logs])
      if (data.extraTurn) {
        setExtraTurn(true)
      }
      if (data.pauseGame) {
        setIsTurn(false)
        setPlayerSelection(true)
        if (data.discardNumber) {
          setDiscardNumber(
            data.discardNumber > data.myHand.length
              ? data.myHand.length
              : data.discardNumber
          )
          setDiscardSelection(true)
        }
      }
      if (counterSpell) {
        setSpellCast(null)
        setCastingSpell(false)
        setMyCountersArray(data.myCountersArray)
      } else {
          if (data.callStack.length && !data.spellCast && !data.pauseGame) {
            const nextSpell = data.callStack.shift()
            if (nextSpell.name === "Unstable Magic") {
              data = spellFunctions[nextSpell.abilities[0]](data)
              for (let i = 0; i < data.onCastGlobals.length; i++) {
                if (data.onCastGlobals[i].activatesOn.includes("noTarget")) {
                  data = resolveSpell(
                    {
                      ...data,
                      myFunction:
                        spellFunctions[data.onCastGlobals[i].abilities[0]],
                    },
                    false
                  )
                } else {
                  data = handleCallStack(data, data.onCastGlobals[i])
                }
              }
              if (data?.spellCast?.name === "Unstable Magic") {
                setSpellCast(null)
                setCastingSpell(false)
              } else {
                setSpellCast(data.spellCast)
                setCastingSpell(true)
              }
            } else {
              setSpellCast(nextSpell)
              setCastingSpell(true)
            }
          }
          else {
            setSpellCast(data.spellCast)

            if (data.spellCast) {
              setCastingSpell(true)
            } else {
              setCastingSpell(false)
            }
          }
        
        setMyCountersArray(data.myCountersArray)
      }
      if (resolveTurnState) {
        setUsedPlayerAbility(false)
        setUsedThisAbility("")
        setAttacked(() =>
          data.tappedCreatures.length ? data.tappedCreatures : []
        )
        data.attacked = data.tappedCreatures
      } else {
        data.attacked = data.attacked.filter((card) =>
          data.attackerCurrentBoard.find((minion) => minion.id === card.id)
        )
        const uniqueCards = []
        const uniqueAttackedArr = []
        for (let i = 0; i < data.attacked.length; i++) {
          if (!uniqueCards.includes(data.attacked[i].id)) {
            uniqueAttackedArr.push(data.attacked[i])
            uniqueCards.push(data.attacked[i].id)
          }
        }
        setAttacked(uniqueAttackedArr)
        setUsedPlayerAbility(data.usedPlayerAbility)
      }
      setCallStack((callStack) => (data.callStack ? data.callStack : callStack))
      setTheirBoard(data.defenderCurrentBoard)
      setMyBoard(data.attackerCurrentBoard)
      setHand(data.myHand)
      setOpponentsHand(data.theirHand)
      setTheirGraveyard(data.theirCurrentGY)
      setMyGraveyard(data.myCurrentGY)
      setPlayer(data.myPlayer)
      setOpponent(data.theirPlayer)
      setCurrentMana(data.myCurrentMana)
      setGameDeck(data.deck)
      setTheirGameDeck(data.theirDeck)
      setSpellID(data.spellID ? data.spellID : null)
      setMana(data.mana)
      setMyExileZone(data.myExileZone)
      setOpponentExileZone(data.opponentExileZone)
      setDrawFromGraveyard(data.drawFromGraveyard)
      setOpponentDrawFromGraveyard(data.opponentDrawFromGraveyard)
      setTheirAttacked(data.theirAttacked)
      setOnDeathGlobals(data.onDeathGlobals)
      setOpponentDeathGlobals(data.opponentDeathGlobals)
      setOnCastGlobals(data.onCastGlobals)
      setOpponentCastGlobals(data.opponentCastGlobals)
      setOndrawGlobals(data.onDrawGlobals)
      setOpponentDrawGlobals(data.opponentDrawGlobals)
      setOnHealGlobals(data.onHealGlobals)
      setOpponentHealGlobals(data.opponentHealGlobals)
      setMyKingdomOfHeaven(data.myKingdomOfHeaven)
      setOpponentKingdomOfHeaven(data.opponentKingdomOfHeaven)
      setMyKingdomOfHell(data.myKingdomOfHell)
      setOpponentKingdomOfHell(data.opponentKingdomOfHell)
      setOnPlayGlobals(data.onPlayGlobals)
      setOpponentOnPlayGlobals(data.opponentOnPlayGlobals)
      setProphecyArray(data.prophecyArray)
      setOpponentsCountersArray(data.opponentsCountersArray)
      setMyArtifacts(data.myArtifacts)
      setOpponentArtifacts(data.opponentArtifacts)
      setDoubleCast(
        data.doubleCast.reset
          ? { doubleCast: false, reset: false }
          : data.doubleCast
      )
      if (!data.handFunction) {
        setHandChoices(data.handChoices)
        setHandNumber(data.handNumber)
        setHandSelection(data.handSelection)
      } else {
        setIsTurn(true)
      }
      setSpellReduction(
        data.spellReduction.reset
          ? {
              reduceCost: 0,
              reducedCost: false,
              lifeCost: { payLife: false, amount: 0 },
              dontSplice: false,
              reset: false,
            }
          : {
              ...data.spellReduction,
              reduceCost: Math.min(data.spellReduction.reduceCost, 10000),
            }
      )
      setCreatureReduction(
        data.creatureReduction.reset
          ? {
              reduceCost: 0,
              reducedCost: false,
              lifeCost: { payLife: false, amount: 0 },
              dontSplice: false,
              reset: false,
            }
          : data.creatureReduction
      )
      if (data?.handReveal) {
        setHandReveal(true)
      }
      if (turnPass) {
        setHandReveal(false)
        setNextCastDamage({ spellDamage: 0, reset: false })
      } else {
        setNextCastDamage(
          data.nextCastDamage.reset
            ? { spellDamage: 0, reset: false }
            : data.nextCastDamage
        )
      }
      if (turnPass || startGame) {
        soundEffects.flipTurn()
        setIsTurn((turnPass) => !turnPass)
      }
      socket.current.emit(
        "update-board",
        {
          room: room.current,
          socketID: socket.current.socketID,
          ...data,
          turnPass: turnPass,
          gameId: gameId.current,
          attackAnimation: data.attackAnimation ? data.attackAnimation : null,
          firstTurn: firstTurn.current,
        },
        () => {}
      )
    }
    if (data.turnPass) {
      passTurn(data)
    }
  }

  function castSpell(data, topLevel) {
    data = data.myFunction(data)

    if (topLevel) {
      if (
        data?.mySpellCast?.class === "sorcery" &&
        data.doubleCast.doubleCast === true
      ) {
        data.mySpellCast.doubleCast = true
        if (
          data.mySpellCast.activatesOn.includes("noTarget") &&
          data.mySpellCast.name !== "Unstable Magic"
        ) {
          data = data.myFunction(data)
          if (
            data.onCastGlobals.length &&
            data?.mySpellCast?.type === "spell"
          ) {
            for (let i = 0; i < data.onCastGlobals.length; i++) {
              data = handleCallStack(data, data.onCastGlobals[i])
            }
          }
        } else {
          data = handleCallStack(data, data.mySpellCast)
        }
        data.doubleCast.reset = true
      }
      if (data.onCastGlobals.length && data?.mySpellCast?.type === "spell") {
        for (let i = 0; i < data.onCastGlobals.length; i++) {
          if (data.onCastGlobals[i].activatesOn.includes("noTarget")) {
            data = resolveSpell(
              {
                ...data,
                myFunction: spellFunctions[data.onCastGlobals[i].abilities[0]],
              },
              false
            )
          } else {
            data = handleCallStack(data, data.onCastGlobals[i])
          }
        }
      }
      if (
        data.mySpellCast?.type === "spellcaster" &&
        !data.attacked.find((card) => card.id === data.mySpellCast?.id)
      ) {
        data.attacked =
          data.mySpellCast?.type === "spellcaster"
            ? [...data.attacked, data.mySpellCast]
            : data.attacked
      }
      updateBoardState(data)
    }

    return data
  }

  function handleCallStack(data, spell) {
    if (!data.spellCast && !data?.pauseGame) {
      data.spellCast = spell
      data.castingSpell = true
    } else {
      data.callStack.unshift(spell)
    }
    return data
  }

  function isSpectator(socketId, gameUser) {
    return socketId !== gameUser[0] && socketId !== gameUser[1]
  }

  function spellIsCountered(data) {
    return (
      data?.myCountersArray?.length &&
      !data.playerAbility &&
      (data?.target?.class !== "realm" ? true : false)
    )
  }

  function artifactCost(data) {
    data.myCurrentMana -= data?.mySpellCast?.cost
    
    return data
  }

  function spellSplice(data) {
    if (data?.mySpellCast?.type === "spell" && !data.recursion && !data?.mySpellCast?.doubleCast) {
        if (spellReduction.lifeCost.amount !== 10000 && spellReduction.reduceCost !== 10000) {
          const manaCostAfterReduction = Math.max(data.mySpellCast.cost - spellReduction.reduceCost, 0);
          data.myCurrentMana -= manaCostAfterReduction;
          data.spellReduction.reset = true;
        }else{
          data.spellReduction.reset = true;
        }

        if (spellReduction.lifeCost.payLife) {
            const lifeCost = (spellReduction.lifeCost.amount === 10000)
                ? data.mySpellCast.cost - spellReduction.reduceCost
                : spellReduction.lifeCost.amount - spellReduction.reduceCost;
            data = resolveDamage(data, lifeCost, "self", false);
        }

        if (!spellReduction?.dontSplice) {
            const spellIndex = data.myHand.findIndex(hand => hand.id === data.mySpellCast.id);
            if (spellIndex !== -1) {
                const myCastedSpell = data.myHand.splice(spellIndex, 1)[0];
                if (data.mySpellCast.class !== "incarnate" && !data.mySpellCast.token && !data.mySpellCast.oneUse) {
                    data.myCurrentGY.unshift(myCastedSpell);
                } else {
                    data.myExileZone.unshift(myCastedSpell);
                }
            }
        }

        data.myPlayer.spellsCasted += 1;
    } else if (data?.mySpellCast?.type === "spell" && data.recursion) {
        data.myCurrentMana -= data.mySpellCast.recursionCost;
        const gyIndex = data.myCurrentGY.findIndex(GY => GY.id === data.mySpellCast.id);
        if (gyIndex !== -1) {
            data.myCurrentGY.splice(gyIndex, 1);
        }
        data.myPlayer.spellsCasted += 1;
    }

    return data;
}

  // Handles cards that target your own GY
  function castOnGY(card, index) {
    if (
      socket.current.socketID === gameUsers.current[0] ||
      socket.current.socketID === gameUsers.current[1]
    ) {
      let spellAbility
      if (spellCast) {
        spellAbility = spellCast.abilities[0]
      }
      resolveSpell({
        target: card,
        index,
        myFunction: spellFunctions[spellAbility],
      })
    }
  }

  const canAttack = (data) => {
    return (
      ((data.target.type === "creature" ||
        data.target.type === "spellcaster") &&
        data.target.effects.includes("provoke")) ||
      ((data.target.type === "creature" ||
        data.target.type === "spellcaster") &&
        !data.defenderCurrentBoard.find((card) =>
          card.effects.includes("provoke")
        ))
    )
  }

  function randomId(data) {
    const usedIds = [
        ...data.attackerCurrentBoard.map(obj => obj.Id), 
        ...data.defenderCurrentBoard.map(obj => obj.Id), 
        ...data.myHand.map(obj => obj.Id), 
        ...data.theirHand.map(obj => obj.Id), 
        ...data.theirCurrentGY.map(obj => obj.Id), 
        ...data.myCurrentGY.map(obj => obj.Id), 
        ...data.deck.map(obj => obj.Id), 
        ...data.theirDeck.map(obj => obj.Id), 
        ...data.myArtifacts.map(obj => obj.Id), 
        ...data.opponentArtifacts.map(obj => obj.Id)
    ];

    let newId;
    do {
        newId = Math.floor(Math.random() * 1000000); // You can adjust the range as needed
    } while (usedIds.includes(newId));

    return newId;
}


  function removeElusive(data, location, card) {
    card.elusive = false
    if (card?.veilOfShadows) {
      data = resolveSpell(
        {
          ...data,
          myFunction: spellFunctions[card.abilities[0]],
          location: location,
          veilOfShadowsCard: card,
        },
        false
      )
    }
    return data
  }

  const attackerElusive = (data) => {
    return data.attacker?.elusive
  }

  const targetElusive = (data) => {
    return data.target?.elusive
  }

  const targetDamageToAttacker = (data) => {
    const creature = data.attackerCurrentBoard.find(
      (card) => card.id === data.attacker.id
    )
    if (!attackerElusive(data)) {
      creature.damageTaken += data.target.attack
      if (creature.effects.includes("revenge")) {
        data = resolveSpell(
          {
            ...data,
            myFunction: spellFunctions[creature.abilities[0]],
            location: "attacker",
            damageReceived: data.target.attack,
            revenge: creature,
          },
          false
        )
      }
      if (data.target.effects.includes("bloodthirst")) {
        data = data.calculateHealing(data, data.target.attack, "opponent")
      }
      data.logs.push(
        `${creature.name} deals ${data.target.attack} damage to ${creature.name}`
      )
    } else {
      data = removeElusive(data, "attacker", creature)
      data.logs.push(
        `${creature.name} eluded an attack from ${data.target.name}`
      )
    }

    return data
  }

  const attackerDamageToTarget = (data) => {
    toggleAttackAnimation(data.attacker.id, data.target.id)
    const creature = data.defenderCurrentBoard.find(
      (card) => card.id === data.target.id
    )
    if (!targetElusive(data)) {
      creature.damageTaken += data.attacker.attack
      if (creature.effects.includes("revenge")) {
        data = resolveSpell(
          {
            ...data,
            myFunction: spellFunctions[data.target.abilities[0]],
            location: "defender",
            damageReceived: data.attacker.attack,
            revenge: creature,
          },
          false
        )
      }
      if (data.attacker.effects.includes("bloodthirst")) {
        data = data.calculateHealing(data, data.attacker.attack)
      }
      data.logs.push(
        `${data.attacker.name} deals ${data.attacker.attack} damage to ${creature.name}`
      )
    } else {
      data = removeElusive(data, "defender", creature)
      data.logs.push(
        `${creature.name} eluded an attack from ${data.attacker.name}`
      )
    }

    if(data.myKingdomOfHeaven && isAngel(data.attacker)){
      const randomIndex = Math.floor(Math.random() * data.attackerCurrentBoard.length)
      data.attackerCurrentBoard[randomIndex].defense += 1
    }
    return data
  }

  function calculateAttackDistances(attackerId, defenderId) {
    const attacker = document.getElementById(attackerId)
    const defender = document.getElementById(defenderId)

    if (defenderId === "myLife" && attacker && defender) {
      const attackerRect = attacker.getBoundingClientRect()
      const defenderRect = defender.getBoundingClientRect()

      // Calculate horizontal and vertical distances to move
      const attackDistanceX =
        defenderRect.left +
        defenderRect.width / 2 -
        (attackerRect.left + attackerRect.width / 2)

      const attackDistanceY =
        defenderRect.top +
        defenderRect.height / 2 -
        (attackerRect.bottom + attackerRect.height) / 1.65

      return { attackDistanceX, attackDistanceY }
    } else if (attacker && defender) {
      const attackerRect = attacker.getBoundingClientRect()
      const defenderRect = defender.getBoundingClientRect()

      // Calculate horizontal and vertical distances to move
      const attackDistanceX =
        defenderRect.left +
        defenderRect.width / 2 -
        (attackerRect.left + attackerRect.width / 2)

      const attackDistanceY =
        defenderRect.top +
        defenderRect.height / 2 -
        (attackerRect.top + attackerRect.height / 8)

      return { attackDistanceX, attackDistanceY }
    }

    return { attackDistanceX: 0, attackDistanceY: 0 } // Default to no movement
  }

  function toggleAttackAnimation(attackerId, defenderId) {
    const { attackDistanceX, attackDistanceY } = calculateAttackDistances(
      attackerId,
      defenderId
    )

    const attacker = document.getElementById(attackerId)

    if (attacker) {
      setAttacker(null)
      // Apply calculated distances as CSS variables
      attacker.style.setProperty("--attack-distance-x", `${attackDistanceX}px`)
      attacker.style.setProperty("--attack-distance-y", `${attackDistanceY}px`)

      // Trigger the animation
      attacker.classList.add("animate-attack")
      if (attacker.classList.contains("add3dHover")) {
        attacker.classList.add("animate3dAttackAttacker")
      }

      animationActive.current = true
      attackerAnimateId.current = attackerId
      setAnimationTrigger(true)

      setTimeout(() => {
        soundEffects.cardAttack()
      }, 900)

      setTimeout(() => {
        animationActive.current = false
        setAnimationTrigger(false)
        attackerAnimateId.current = -1
      }, 1100)

      // Reset after animation
      setTimeout(() => {
        attacker.classList.remove("animate-attack")
        attacker.classList.remove("animate3dAttackAttacker")
        // Remove the transforms to reset the position
        attacker.style.removeProperty("--attack-distance-x")
        attacker.style.removeProperty("--attack-distance-y")
      }, 1500) // Adjust based on the duration of your animation
    }
  }

  function resolveDamage(
    data,
    damage,
    target,
    topLevel = true,
    attackShouldGoOff = false,
    targetCreature
  ) {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    if (data.attacker && (topLevel || attackShouldGoOff) && !damage) {
      if (canAttack(data)) {
        data.didAttackOccur = true
        let combatNormal = true

        if (
          (!data.target.effects.includes("initialBlow") &&
            data.attacker.effects.includes("initialBlow")) ||
          (data.target.effects.includes("initialBlow") &&
            !data.attacker.effects.includes("initialBlow"))
        ) {
          combatNormal = false
        }
        if (combatNormal) {
          data = attackerDamageToTarget(data)
          data = targetDamageToAttacker(data)
        } else {
          if (data.attacker.effects.includes("initialBlow")) {
            if (
              data.target.defense <=
                data.target.damageTaken + data.attacker.attack &&
              !data.target?.elusive
            ) {
              data = attackerDamageToTarget(data)
            } else {
              data = attackerDamageToTarget(data)
              data = targetDamageToAttacker(data)
            }
          } else {
            if (
              data.attacker.defense <=
                data.attacker.damageTaken + data.target.attack &&
              !data.attacker?.elusive
            ) {
              data = targetDamageToAttacker(data)
            } else {
              data = attackerDamageToTarget(data)
              data = targetDamageToAttacker(data)
            }
          }
        }

        let empusaKill = false

        if (
          data.attacker.activatesOn.includes("onAttack") &&
          topLevel &&
          !attackShouldGoOff
        ) {
          data = resolveSpell(
            {
              ...data,
              myFunction: spellFunctions[data.attacker.abilities[0]],
            },
            false
          )
        }

        data = killCreature({
          ...data,
          location: "defender",
          card: data.target,
        })
        if (data.target.name === "Empusa") {
          data = killCreature({
            ...data,
            card: data.attacker,
            kill: true,
            location: "attacker",
          })
          empusaKill = true
        }
        console.log(data.attacker)
        if (!empusaKill) {
          data = killCreature({
            ...data,
            card: data.attacker,
            location: "attacker",
          })
        }

        if (
          data.target.activatesOn.includes("onKill") &&
          data?.defenderKill &&
          data?.defenderSurvive
        ) {
          data = resolveSpell(
            {
              ...data,
              location: "defender",
              myFunction: spellFunctions[data.target.abilities[0]],
            },
            false
          )
        }
        if (
          data.attacker.activatesOn.includes("onKill") &&
          data?.attackerKill &&
          data?.attackerSurvive
        ) {
          data = resolveSpell(
            {
              ...data,
              location: "attacker",
              target: data.attacker,
              index: data.attacker.index,
              myFunction: spellFunctions[data.attacker.abilities[0]],
            },
            false
          )
        }
        data.attackerSurvive = true
        data.defenderSurvive = true

        if (data.target.activatesOn.includes("onBlock")) {
          data = resolveSpell(
            { ...data, myFunction: spellFunctions[data.target.abilities[0]] },
            false
          )
        }
      } else if (
        data.target.type === "opponent" &&
        !data.defenderCurrentBoard.find((card) =>
          card.effects.includes("provoke")
        )
      ) {
        data = attackerFaceDamage(data, topLevel)
      }
    } else if (damage) {
      damage += data.nextCastDamage.spellDamage
      if (
        (data?.target?.type === "opponent" &&
          target !== "dontKill" &&
          target !== "self") ||
        target === "opponent"
      ) {
        data.theirPlayer.damageTaken += damage
      } else if (target === "self") {
        data.myPlayer.damageTaken += damage
      } else if (
        data?.target?.type === "creature" ||
        data?.target?.type === "spellcaster" ||
        target === "dontKill"
      ) {
        if (target === "dontKill") {
          if (!targetCreature?.elusive) {
            data.logs.push(`${targetCreature} takes ${damage} spell damage`)
            if (targetCreature.effects.includes("revenge")) {
              data = resolveSpell(
                {
                  ...data,
                  myFunction: spellFunctions[targetCreature.abilities[0]],
                  damageReceived: damage,
                  revenge: targetCreature,
                },
                false
              )
            }
            return damage
          } else {
            const theirMinion =
              data.defenderCurrentBoard[
                data.defenderCurrentBoard.findIndex(
                  (card) => card.id === targetCreature.id
                )
              ]
            const myMinion =
              data.attackerCurrentBoard[
                data.attackerCurrentBoard.findIndex(
                  (card) => card.id === targetCreature.id
                )
              ]
            if (theirMinion) data = removeElusive(data, "defender", theirMinion)
            if (myMinion) data = removeElusive(data, "attacker", myMinion)

            data.logs.push(`${targetCreature} eludes ${damage} spell damage`)
            return 0
          }
        }
        if (!data.target?.elusive) {
          data.logs.push(`${data.target.name} takes ${damage} spell damage`)
          data.target.damageTaken += damage
          data.defenderCurrentBoard[data.index].damageTaken += damage
          if (data.target.effects.includes("revenge")) {
            data = resolveSpell(
              {
                ...data,
                myFunction: spellFunctions[data.target.abilities[0]],
                damageReceived: damage,
                revenge: data.target,
              },
              false
            )
          }
        } else {
          data.logs.push(`${data.target.name} eludes ${damage} spell damage`)
          const minion =
            data.defenderCurrentBoard[
              data.defenderCurrentBoard.findIndex(
                (card) => card.id === data.target.id
              )
            ]
          if (minion) data = removeElusive(data, "defender", minion)
        }
        data = killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[data.index],
        })
      }
    }

    return data
  }

  function attackerFaceDamage(data, topLevel) {
    toggleAttackAnimation(data.attacker.id, "opponentLife")
    data.didAttackOccur = true
    if (data.theirPlayer.durability === 0) {
      if (!data.attacker.effects.includes("noFaceDamage")) {
        data.theirPlayer.damageTaken += data.attacker.attack
        data.logs.push(
          `${data.attacker.name} deals ${data.attacker.attack} damage to ${data.theirPlayer.name}`
        )
        if (data.attacker.effects.includes("bloodthirst")) {
          data.logs.push(`${data.attacker.name} bloodthirst activates`)
          data = data.calculateHealing(data, data.attacker.attack)
        }
      }
    } else {
      data.theirPlayer.durability -= 1
      if (!data.attacker.effects.includes("noFaceDamage")) {
        data.attacker.attack > data.theirPlayer.block
          ? (data.theirPlayer.damageTaken +=
              data.attacker.attack - data.theirPlayer.block)
          : (data.theirPlayer.damageTaken += 0)
        data.logs.push(
          `${data.attacker.name} deals ${
            data.attacker.attack > data.theirPlayer.block
              ? data.attacker.attack - data.theirPlayer.block
              : 0
          } damage and removes 1 durability`
        )
      }
      data.theirPlayer.durability === 0
        ? (data.theirPlayer.block = 0)
        : (data.theirPlayer.block = data.theirPlayer.block)
      if (data.attacker.effects.includes("bloodthirst")) {
        data.logs.push(`${data.attacker.name} bloodthirst activates`)
        data = data.calculateHealing(data, data.attacker.attack)
      }
    }
    if (
      (data.attacker.activatesOn.includes("onAttack") ||
        data.attacker.effects.includes("poisonous")) &&
      topLevel
    ) {
      data = resolveSpell(
        {
          ...data,
          myFunction: spellFunctions[data.attacker.abilities[0]],
          onAttack: true,
        },
        false
      )
    }

    return data
  }

  // Handles damage calcuations and board states after those calculations
  function receiveDamage(target, index, queueAttacker = null) {
    if (animationActive.current) {
      for (let i = 0; i <= animationStack.current.length; i++) {
        if (
          animationStack.current[i]?.attacker?.id === attacker?.id ||
          attackerAnimateId.current === attacker?.id
        )
          return
      }
      animationStack.current = [
        ...animationStack.current,
        { attacker: attacker, target, index, function: "receiveDamage" },
      ]
      return
    }

    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    if (target?.type === "opponent") logGameAction(null, "receiveDamage")

    let data = setInitialState({
      attacker: queueAttacker,
      target: target,
      index: index,
      myFunction: spellFunctions[spellCast?.abilities[0]],
    })

    if (
      castingSpell &&
      !target?.spellImmune &&
      !target?.opponentImmune &&
      (!data.mySpellCast.activatesOn.includes("friendly") ||
        data.mySpellCast.activatesOn.includes("anyMinion")) &&
      !data.mySpellCast.activatesOn.includes(
        "opponentChooses" &&
          !data.mySpellCast.activatesOn.includes("theirGraveyard")
      )
    ) {
      if (
        data.defenderCurrentBoard.find((card) =>
          card.effects.includes("guardian")
        )
      ) {
        data.index = data.defenderCurrentBoard.findIndex((card) =>
          card.effects.includes("guardian")
        )
        data.target = data.defenderCurrentBoard.find((card) =>
          card.effects.includes("guardian")
        )
      }
      if (spellCast.type === "spell")
        setCardCasting(findCard(data, spellCast.id))
      setAttacker(null)
      resolveSpell({
        ...data,
        attacked: spellCast?.spellcaster
          ? [spellCast, ...data.attacked]
          : data.attacked,
      })
    } else if (data.attacker) {
      setPassTurnDisabled(true)
      data = resolveDamage(data)

      if (data.didAttackOccur) {
        data.attacked = [...data.attacked, data.attacker]
        data.attackerAttacked = true
      }
      setTimeout(() => {
        data.attackAnimation = true
        setPassTurnDisabled(false)
        updateBoardState(data)
      }, 1000)
    }
  }
  // If you have a special incarnate card played this handles their abilities
  function castPlayerAbility(ability) {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    if (usedPlayerAbility) return
    logGameAction({ ability }, "playerAbility")
    resolveSpell({ myFunction: spellFunctions[ability], playerAbility: true })
    setUsedThisAbility(ability)
    setUsedPlayerAbility(true)
  }
  // Converts ability names to a readable string format
  const newString = (string) => {
    const splitLocation = string
      .split("")
      .findIndex((letter) => letter === letter.toUpperCase())
    return (
      string.slice(0, 1).toUpperCase() +
      string.slice(1, splitLocation) +
      " " +
      string.slice(splitLocation)
    )
  }

  function continueOn() {
    navigate("/deckbuilder")
  }

  function drawCards(data, number, player = "self", gameStart = false) {
    const drawFromGraveyard = {
      self: data.drawFromGraveyard,
      opponent: data.opponentDrawFromGraveyard,
    }
    const deck = {
      self: data.deck,
      opponent: data.theirDeck,
    }
    const hand = {
      self: data.myHand,
      opponent: data.theirHand,
    }
    const currentGY = {
      self: data.myCurrentGY,
      opponent: data.theirCurrentGY,
    }
    const playerObject = {
      self: data.myPlayer,
      opponent: data.theirPlayer,
    }

    const whoDraws = {
      self: player === "self" || player === "both",
      opponent: player === "opponent" || player === "both",
    }

    const drawnCards = {
      self: [],
      opponent: [],
    }

    for (let i = 0; i <= 1; i++) {
      const currentDraw = i === 0 ? "self" : "opponent"
      if (whoDraws[currentDraw]) {
        if (drawFromGraveyard[currentDraw]) {
          if (number > currentGY[currentDraw].length) {
            // Move non-realm cards from currentGY to drawnCards if there are not enough cards
            for (let i = 0; i < currentGY[currentDraw].length; i++) {
              const card = currentGY[currentDraw][i]
              if (card.class !== "realm") {
                // Check if the card's class is not "realm"
                drawnCards[currentDraw].push(card)
              }
            }
            playerObject[currentDraw].damageTaken +=
              drawnCards[currentDraw].length
            currentGY[currentDraw] = [] // Empty currentGY after moving cards
          } else {
            // Draw 'number' of cards randomly from currentGY
            for (let i = 0; i < number; i++) {
              const randomIndex = Math.floor(
                Math.random() * currentGY[currentDraw].length
              )
              const drawnCard = currentGY[currentDraw][randomIndex]
              if (drawnCard.class !== "realm") {
                // Check if the card's class is not "realm"
                drawnCards[currentDraw].push(drawnCard)
                currentGY[currentDraw].splice(randomIndex, 1) // Remove the drawn card from currentGY
              }
            }
            playerObject[currentDraw].damageTaken +=
              drawnCards[currentDraw].length
          }
        } else {
          if (number > deck[currentDraw].length) {
            drawnCards[currentDraw].push.apply(
              drawnCards[currentDraw],
              deck[currentDraw].splice(0, deck[currentDraw].length)
            )
          } else {
            drawnCards[currentDraw].push.apply(
              drawnCards[currentDraw],
              deck[currentDraw].splice(0, number)
            )
          }
        }
      }
      hand[currentDraw].push.apply(hand[currentDraw], drawnCards[currentDraw])
    }

    data.drawnCards = [...drawnCards.self]
    data.theirDrawnCards = [...drawnCards.opponent]

    if (!gameStart) {
      for (let i = 0; i < data.onDrawGlobals.length; i++) {
        data.draw = "myDraw"
        data = spellFunctions[data.onDrawGlobals[i].abilities[0]](data)
      }
      for (let i = 0; i < data.opponentDrawGlobals.length; i++) {
        data.draw = "opponentDraw"
        data = spellFunctions[data.opponentDrawGlobals[i].abilities[0]](data)
      }

      for (let i = 0; i < drawnCards.self.length; i++) {
        if (drawnCards.self[i].effects.includes("whenDrawn")) {
          data = spellFunctions[drawnCards.self[i].abilities[0]]({
            ...data,
            drawnCardLoop: drawnCards.self[i],
            location: "attacker",
          })
          delete data.drawnCardLoop
        }
        if (drawnCards.self[i].effects.includes("playWhenDrawn")) {
          const playedCreature = data.myHand.splice(
            data.myHand.findIndex((card) => card.id === drawnCards.self[i].id),
            1
          )[0]
          data = playCreature({
            ...data,
            creature: playedCreature,
            castSpell: true,
          })
        }
      }

      for (let i = 0; i < drawnCards.opponent.length; i++) {
        if (drawnCards.opponent[i].effects.includes("whenDrawn")) {
          data = spellFunctions[drawnCards.opponent[i].abilities[0]]({
            ...data,
            theirDrawnCardLoop: drawnCards.opponent[i],
            location: "defender",
          })
          delete data.theirDrawnCardLoop
        }
        // if (drawnCards.opponent[i].effects.includes("playWhenDrawn")) {
        //   const playedCreature = data.theirHand.splice(
        //     data.theirHand.findIndex(
        //       (card) => card.id === drawnCards.opponent[i].id
        //     ),
        //     1
        //   )[0]
        //   data = playCreatureOnOpponentBoard({
        //     ...data,
        //     creature: playedCreature,
        //     castSpell: true,
        //   })
        // }
      }
    }

    data.drawnCards = [...drawnCards.self]
    data.theirDrawnCards = [...drawnCards.opponent]
    return data
  }

  function playerChoice(index) {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    if (
      spellCast?.activatesOn?.includes("selfChoice") ||
      spellCast?.spellcaster
    ) {
      setPlayerSelectionOptions([])
      setIsTurn(true)
      playerChoiceIndex.current = index
      logGameAction({ index }, "playerChoice")
    } else {
      setPlayerSelection(false)
      setPlayerSelectionOptions([])
      const isRecursionHappening = recursionTrue
      setRecursionTrue(false)
      socket.current.emit("choice-picked", {
        room: room.current,
        socketID: socket.current.socketID,
        index,
        recursion: isRecursionHappening,
      })
    }
  }

  function realmCard(card, index) {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    if (card.activatesOn.includes("castAbility")) {
      setCardCasting(card)
      resolveSpell({
        myFunction: spellFunctions[card.abilities[0]],
        castAbility: true,
      })
    } else {
      // TODO: Fix this section of code to use updateBoardState Function
      const data = setInitialState({})
      data.myCurrentGY = [data.myHand.splice(index, 1)[0], ...data.myCurrentGY]
      data.myCurrentMana -= card.cost
      updateBoardState(data)
    }
  }

  function logGameAction(card, action, data = null) {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    if (!data) {
      clickNumber.current += 1

      // Ensure that gameReplay.current[turnNumber.current] is an object
      if (!gameReplay.current[turnNumber.current]) {
        gameReplay.current[turnNumber.current] = {}
      }

      const logData = {
        clickedCard: card,
        action: action,
      }

      // Now, set the log data
      gameReplay.current[turnNumber.current][clickNumber.current] = logData
    } else {
      // Ensure that gameReplay.current[turnNumber.current][clickNumber.current] is an object
      if (!gameReplay.current[turnNumber.current]) {
        gameReplay.current[turnNumber.current] = {}
      }

      // Ensure that gameReplay.current[turnNumber.current][clickNumber.current] is an object
      if (!gameReplay.current[turnNumber.current][clickNumber.current]) {
        gameReplay.current[turnNumber.current][clickNumber.current] = {}
      }

      // gameReplay.current[turnNumber.current][clickNumber.current].amendedData = data
    }
  }

  function addToSideBoard(index) {
    const movedCard = gameDeck.splice(index, 1)[0]
    setGameSideBoard((sideBoard) => [...sideBoard, movedCard])
  }

  function addToDeck(index) {
    const movedCard = gameSideBoard.splice(index, 1)[0]
    setGameDeck((gameDeck) => [...gameDeck, movedCard])
  }

  function startMulligan() {
    setStatTrackingDeck(gameDeck)
    socket.current.emit(
      "new-post-sideboard",
      {
        room: room.current,
        socketID: socket.current.socketID,
        gameDeck: gameDeck,
        sideBoard: gameSideBoard,
      },
      () => {
        setSideBoarding(false)
        setApplyAbsolute(true)
      }
    )
  }

  function discardCards(data, number, random, player, updateBoard = false) {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    if (updateBoard) logGameAction(null, "discardCards")
    data = data || setInitialState({})

    const players = {
      self: player === "self" || player === "both",
      opponent: player === "opponent" || player === "both",
    }

    const hands = {
      self: data.myHand,
      opponent: data.theirHand,
    }

    const graveyards = {
      self: data.myCurrentGY,
      opponent: data.theirCurrentGY,
    }

    const resolveSpells = {
      self: [],
      opponent: [],
    }

    const discardedCards = {
      self: [],
      opponent: [],
    }

    if (random) {
      for (let i = 0; i <= 1; i++) {
        const currentDiscard = i === 0 ? "self" : "opponent"
        const discardAmount =
          number > hands[currentDiscard].length
            ? hands[currentDiscard].length
            : number

        if (players[currentDiscard]) {
          for (let i = 0; i < discardAmount; i++) {
            const randomIndex = Math.floor(
              Math.random() * hands[currentDiscard].length
            )
            const discardCard = hands[currentDiscard].splice(
              hands[currentDiscard][randomIndex],
              1
            )[0]
            discardedCards[currentDiscard].push(discardCard)
            if (discardCard.effects.includes("ambush")) {
              if (discardCard.type !== "spell") {
                if (currentDiscard === "self") {
                  data = playCreature({
                    ...data,
                    creature: discardCard,
                    castSpell: true,
                    location: "attacker",
                  })
                  data.myPlayer.damageTaken += 1
                } else {
                  data = playCreatureOnOpponentBoard({
                    ...data,
                    creature: discardCard,
                    castSpell: true,
                    location: "defender",
                  })
                  data.theirPlayer.damageTaken += 1
                }
              } else {
                resolveSpells[currentDiscard].push(discardCard)
                graveyards[currentDiscard].unshift(discardCard)
              }
            } else {
              graveyards[currentDiscard].unshift(discardCard)
            }
          }
        }
      }
    } else {
      for (let i = 0; i < discardChoices.length; i++) {
        if (discardChoices[i].effects.includes("ambush")) {
          if (discardChoices[i].type !== "spell") {
            data = playCreature({
              ...data,
              creature: discardChoices[i],
              castSpell: true,
            })
            data.myPlayer.damageTaken += 1
            hands["self"].splice(
              hands["self"].findIndex(
                (card) => card.id === discardChoices[i].id
              ),
              1
            )
          } else {
            graveyards["self"].unshift(
              hands["self"].splice(
                hands["self"].findIndex(
                  (card) => card.id === discardChoices[i].id
                ),
                1
              )[0]
            )
            resolveSpells["self"].push(discardChoices[i])
          }
        } else {
          graveyards["self"].unshift(
            hands["self"].splice(
              hands["self"].findIndex(
                (card) => card.id === discardChoices[i].id
              ),
              1
            )[0]
          )
        }
      }
    }

    data.discardedCards = discardedCards

    for (let i = 0; i <= 1; i++) {
      const currentCaster = i === 0 ? "self" : "opponent"

      if (resolveSpells[currentCaster].length) {
        for (let i = 0; i < resolveSpells[currentCaster].length; i++) {
          data = resolveSpell(
            {
              ...data,
              myFunction:
                spellFunctions[resolveSpells[currentCaster][i].abilities[0]],
              location: currentCaster === "self" ? "attacker" : "defender",
              discardCard: true,
            },
            false
          )
        }
      }
    }

    if (updateBoard) {
      updateBoardState(data)
      setIsTurn(true)
    }
    return data
  }

  const connectToServer = () => {
    socket.current.connect()
  }

  const disconnectFromServer = () => {
    socket.current.disconnect()
  }

  const receiveHealing = () => {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    if (spellCast?.activatesOn.includes("self")) {
      logGameAction(null, "receiveHealing")
      let data = setInitialState({})
      data = resolveSpell(
        {
          ...data,
          myFunction: spellFunctions[spellCast.abilities[0]],
          target: player,
        },
        false
      )
      updateBoardState(data)
    }
  }

  function handSelectionFunction() {
    if (isSpectator(socket.current.socketID, gameUsers.current)) return
    logGameAction(null, "handSelectionFunction")
    let data = setInitialState({})
    data.handFunction = true
    data = resolveSpell(
      { ...data, myFunction: spellFunctions[spellCast.abilities[0]] },
      false
    )
    updateBoardState(data)
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60

    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`
  }

  function attackAll() {
    const data = setInitialState({})
    const attackWentThrough = { current: false }
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      const attacker = data.attackerCurrentBoard[i]

      if (
        data.attacked.find(
          (card) =>
            card.id === attacker.id || attackerAnimateId.current === attacker.id
        ) ||
        attacker.effects.includes("cantAttack") ||
        attacker.attack < 1
      )
        continue

      data.attacked.push(attacker)

      animationStack.current.push({
        attacker: attacker,
        target: data.theirPlayer,
        index: null,
        function: "receiveDamage",
      })
      if (!attackWentThrough.current) attackWentThrough.current = true
    }

    if (attackWentThrough.current) {
      setPassTurnDisabled(true)
      setAttacked(data.attacked)
      setAnimationTrigger(true)
    }
  }

  function passTurnInActive() {
    return (
      !isTurn ||
      callStack.length > 0 ||
      togglePassTurn ||
      animationStack.current.includes("passTurn")
    )
  }

  const resetStateToDefaults = () => {
    setPassTurnDisabled(false)
    setStateUpdate(false)
    setWorship({})
    setWorshipArray([])
    firstTurn.current = false
    setResolveMulligan(true)
    setOpponentMulligan(true)
    setIsTurn(false)
    room.current = ""
    clickNumber.current = 0
    turnNumber.current = 1
    gameReplay.current = {}
    setDiscardSelection(false)
    setDiscardChoices([])
    setDiscardNumber(0)
    setHandSelection(false)
    setHandChoices([])
    setHandNumber(0)
    playerChoiceIndex.current = null
    setGameDeck([])
    setTheirGameDeck([])
    setHand([])
    originalOpponentDeck.current = []
    setOpponentsHand([])
    setMyBoard([])
    setTheirBoard([])
    setMana(0)
    setCurrentMana(0)
    setOpponentsMana(0)
    setOpponentsCurrentMana(0)
    setAttacker(null)
    setAttacked([])
    setTheirAttacked([])
    setMyGraveyard([])
    setTheirGraveyard([])
    setCastingSpell(false)
    setSpellCast(null)
    opponentUser.current = {}
    setExpandMyGY(false)
    setExpandOpponentGY(false)
    setTogglePassTurn(false)
    setAnimationTrigger(false)
    setApplyAbsolute(false)
    animationStack.current = []
    setPlayer({
      userName: user.userName,
      defense: 30,
      damageTaken: 0,
      type: "self",
      block: 0,
      durability: 0,
      poison: 0,
      revitalize: 0,
      spellsCasted: 0,
      healAbsorb: 0,
      sacrifices: 0,
    })
    setOpponent({
      userName: "Guest",
      defense: 30,
      damageTaken: 0,
      type: "opponent",
      block: 0,
      durability: 0,
      poison: 0,
      revitalize: 0,
      spellsCasted: 0,
      healAbsorb: 0,
      sacrifices: 0,
    })
    setDontResolveUpkeep(false)
    setSpellID(null)
    setUsedPlayerAbility(false)
    setExtraTurn(false)
    gameIsFinished.current = false
    setUsedThisAbility("")
    setDrawFromGraveyard(false)
    setOpponentLeft(false)
    setOpponentDrawFromGraveyard(false)
    setStatsLogged(false)
    setPlayerSelection(false)
    setPlayerSelectionOptions([])
    setOpponentChoice(null)
    setRecursionTrue(false)
    setMulliganedCards([])
    gameUsers.current = []
    spectate.current = false
    setSideBoarding(true)
    setOpponentSideBoarding(true)
    setGameSideBoard([])
    setTheirGameSideBoard([])
    setDrawnStarterCards(false)
    setMyCountersArray([])
    setOpponentsCountersArray([])
    setOnDeathGlobals([])
    setOpponentDeathGlobals([])
    setOnCastGlobals([])
    setOpponentCastGlobals([])
    setOndrawGlobals([])
    setOpponentDrawGlobals([])
    setOnHealGlobals([])
    setOpponentHealGlobals([])
    setOnPlayGlobals([])
    setOpponentOnPlayGlobals([])
    setOpponentCardBack("")

    setSpellReduction({
      reduceCost: 0,
      reducedCost: false,
      lifeCost: { payLife: false, amount: 0 },
      dontSplice: false,
      reset: false,
    })
    setCreatureReduction({
      reduceCost: 0,
      reducedCost: false,
      lifeCost: { payLife: false, amount: 0 },
      dontSplice: false,
      reset: false,
    })
    setMyKingdomOfHeaven(false)
    setOpponentKingdomOfHeaven(false)
    setMyKingdomOfHell(false)
    setOpponentKingdomOfHell(false)
    setMyArtifacts([])
    setOpponentArtifacts([])
    // setLogs([])
    setProphecyArray([])
    setOpponentProphecyArray([])
    setCallStack([])
    setValidDeckCheck([])
    setNextCastDamage({
      spellDamage: 0,
      reset: false,
    })
    setDoubleCast({
      doubleCast: false,
      reset: false,
    })
    gameId.current = 0
    initialState.current = {}
    setHandReveal(false)
    setTheVoid([])
    setTimeInQueue(0)
    startTimer.current = false
  }

  return (
    <div id="mainDivContainer">
      {process.env.NODE_ENV === "development" ? (
        <div>
          <button onClick={connectToServer}>Connect</button>
          <button onClick={disconnectFromServer}>Disconnect</button>
          <button
            onClick={() => {
              setOpponent((opponent) => ({
                ...opponent,
                damageTaken: opponent.defense,
              }))
            }}
          >
            Kill Opponent
          </button>
        </div>
      ) : null}
      {startTimer.current && (
        <div className="gameFontColors infoText">
          <h1>{formatTime(timeInQueue)}</h1>
        </div>
      )}
      <InfoText infoText={infoText} applyAbsolute={applyAbsolute} />
      {validDeckCheck.length > 0 &&
        validDeckCheck.map((card) => {
          return (
            <InfoText
              infoText={`${card.name}: ${card.count} Limit: ${
                rarityIndex[card.rarity]
              }`}
            />
          )
        })}
      {!room.current && !gameIsFinished.current && !infoText && (
        <DeckPageMenu
          // createGame={createGame}
          joinRankedMatchmaking={joinRankedMatchmaking}
          joinNormalMatchmaking={joinNormalMatchmaking}
          loadDeck={loadDeckGamePage}
          socketConnected={socket.current?.connected ? true : false}
          deck={deck}
          deckList={deckList}
          user={user}
          deckName={deckName}
        />
      )}
      {!hideRooms && !gameIsFinished.current && (
        <div>
          {!room.current &&
            !gameIsFinished.current &&
            rooms.map((room, index) => (
              <div
                key={index}
                className="biggerButton gameFontColors"
                onClick={() => joinGame(room.gameName, room.gameStarted)}
              >
                {!room.gameStarted
                  ? room.gameName
                  : room.gameName.split(" ").slice(
                      0,
                      room.gameName.split(" ").findIndex((char) => char === "-")
                    ) + " - Spectate"}
              </div>
            ))}
        </div>
      )}
      {sideBoarding && !gameIsFinished.current && room.current && (
        <Sideboard
          gameDeck={gameDeck}
          gameSideBoard={gameSideBoard}
          theirGameSideBoard={theirGameSideBoard}
          theirGameDeck={theirGameDeck}
          addToSideBoard={addToSideBoard}
          addToDeck={addToDeck}
          startMulligan={startMulligan}
          playedFirst={firstTurn.current}
          user={user}
          opponentUser={opponentUser.current}
        />
      )}
      {room.current && !gameIsFinished.current && !sideBoarding && (
        <div className="container-test">
          <Board
            setExpandMyArtifacts={setExpandOpponentArtifacts}
            hand={opponentsHand}
            graveyard={theirGraveyard}
            spellCast={spellCast}
            board={theirBoard}
            player={opponent}
            playerAbilityClick={false}
            discardChoices={discardChoices}
            attacked={theirAttacked}
            playerSelection={playerSelection}
            discardSelection={discardSelection}
            user={opponentUser.current}
            expandGY={expandOpponentGY}
            deck={theirGameDeck}
            spellCounter={opponentsCountersArray}
            mulliganedCards={mulliganedCards}
            resolveMulligan={resolveMulligan}
            mulliganSelection={mulliganSelection}
            clickLocation={clickLocation}
            discardCards={discardCards}
            sendMulligan={sendMulligan}
            playCreature={playCreature}
            discardNumber={discardNumber}
            spectate={spectate}
            sideBoarding={sideBoarding}
            opponentSideBoarding={opponentSideBoarding}
            whoseSide={"opponentSide"}
            usedThisAbility={usedThisAbility}
            newString={newString}
            castPlayerAbility={castPlayerAbility}
            mana={opponentsMana}
            currentMana={opponentsCurrentMana}
            receiveDamage={receiveDamage}
            receiveHealing={receiveHealing}
            handChoices={handChoices}
            handNumber={handNumber}
            handSelection={handSelection}
            attacker={attacker}
            handSelectionFunction={handSelectionFunction}
            worship={worship}
            worshipArray={worshipArray}
            availableWorshipMana={availableWorshipMana}
            cancelSummon={cancelSummon}
            prophecyArray={prophecyArray}
            opponentProphecyArray={opponentProphecyArray}
            handReveal={handReveal}
            opponentCardBack={opponentCardBack}
            setExpandMyGY={setExpandOpponentGY}
            artifacts={opponentArtifacts}
            expandArtifacts={expandOpponentArtifacts}
            gyOrArtifacts={opponentGyOrArtifacts}
            changeGYDisplay={() => setOpponentGyOrArtifacts((gy) => !gy)}
            exileZone={opponentExileZone}
            key={"opponentBoard"}
          />
          <div className="space-between">
            <button
              className={`biggerButton ${
                !passTurnInActive() ? "glow-button" : ""
              }`}
              onClick={() => passTurn()}
              disabled={passTurnInActive()}
            >
              Pass Turn
            </button>
            {isTurn &&
              myBoard.length >
                attacked.length +
                  myBoard.filter(
                    (card) =>
                      card.effects.includes("cantAttack") || card.attack < 1
                  ).length && (
                <button
                  disabled={
                    theirBoard.find((card) => card.effects.includes("provoke")) ||
                    animationStack.length > 0 ||
                    animationActive.current ||
                    !myBoard.filter((card) => card.attack > 0).length > 0 ||
                    spellCast
                  }
                  className="biggerButton attackAllButton"
                  onClick={attackAll}
                >
                  Attack All
                  {theirBoard.find((card) =>
                    card.effects.includes("provoke")
                  ) && (
                    <div id="hideDisplay">
                      Must attack <span class="giveGreen">Provoke</span> minions
                      First
                    </div>
                  )}
                </button>
              )}
            {spellCast && !opponentChoosing ? (
              <button
                onClick={cancelSpell}
                className="biggerButton glow-button"
              >
                Cancel Spell
              </button>
            ) : (
              <div></div>
            )}
          </div>
          <Board
            setExpandMyArtifacts={setExpandMyArtifacts}
            setExpandMyGY={setExpandMyGY}
            hand={hand}
            graveyard={myGraveyard}
            spellCast={spellCast}
            board={myBoard}
            player={player}
            playerAbilityClick={true}
            discardChoices={discardChoices}
            attacked={attacked}
            playerSelection={playerSelection}
            discardSelection={discardSelection}
            user={user}
            expandGY={expandMyGY}
            deck={gameDeck}
            spellCounter={myCountersArray}
            mulliganedCards={mulliganedCards}
            resolveMulligan={resolveMulligan}
            mulliganSelection={mulliganSelection}
            clickLocation={clickLocation}
            discardCards={discardCards}
            sendMulligan={sendMulligan}
            playCreature={playCreature}
            discardNumber={discardNumber}
            spectate={spectate}
            sideBoarding={sideBoarding}
            opponentSideBoarding={opponentSideBoarding}
            whoseSide={"mySide"}
            usedThisAbility={usedThisAbility}
            newString={newString}
            castPlayerAbility={castPlayerAbility}
            attacker={attacker}
            mana={mana}
            currentMana={currentMana}
            receiveDamage={receiveDamage}
            receiveHealing={receiveHealing}
            handChoices={handChoices}
            handNumber={handNumber}
            handSelection={handSelection}
            handSelectionFunction={handSelectionFunction}
            worship={worship}
            worshipArray={worshipArray}
            availableWorshipMana={availableWorshipMana}
            cancelSummon={cancelSummon}
            prophecyArray={prophecyArray}
            opponentProphecyArray={opponentProphecyArray}
            isolationCheck={isolationCheck}
            cardBack={user?.usersettings?.cardBack?.[deckName]}
            artifacts={myArtifacts}
            expandArtifacts={expandMyArtifacts}
            gyOrArtifacts={gyOrArtifacts}
            changeGYDisplay={() => setGyOrArtifacts((gy) => !gy)}
            exileZone={myExileZone}
            playedFirst={firstTurn.current}
            key={"myBoard"}
          />
        </div>
      )}
      {playerSelection && playerSelectionOptions.length > 0 && (
        <div id="userSelectionBox">
          <div></div>
          <div id="optionBoxDiv">
            <div>Choose One:</div>
            {playerSelectionOptions.map((option, index) => (
              <div
                onClick={() => playerChoice(index)}
                className="playerSelectionOptions"
              >
                {option}
              </div>
            ))}
          </div>
          <div></div>
        </div>
      )}
      {!sideBoarding && gameIsFinished.current && (
        <div id="endGameBox">
          <div id="endGameScreen">
            {infoText === "Victory!" ||
            infoText === "Defeat!" ||
            infoText === "Game is over and room is closed!" ? (
              <div>
                <button onClick={continueOn} className="biggerButton">
                  Continue
                </button>
                {signedIn && user.email !== "cardsofmythology@gmail.com" && (
                  <>
                    {/* <button
                      className="biggerButton"
                      disabled={!socket.current.connected || deck.length !== 40}
                      onClick={() => {
                        resetStateToDefaults()
                        joinNormalMatchmaking()
                      }}
                    >
                      Join Normal Matchmaking
                    </button> */}
                    <button
                      className="biggerButton"
                      disabled={!socket.current.connected || deck.length !== 40}
                      onClick={() => {
                        resetStateToDefaults()
                        joinRankedMatchmaking()
                      }}
                    >
                      Join Queue
                    </button>
                  </>
                )}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      )}
      {/* {logs.length > 0 && <GameLog logs={logs} />} */}
    </div>
  )
}

export default Game