import React, { useState, useContext, useEffect } from "react"
import { GameContext } from "../../App"

const Account = () => {
  const { user, setUser, signedIn, setSignedIn, socket } =
    useContext(GameContext)
  const [password, setPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [changePasswordMessage, setChangePasswordMessage] = useState([])

  useEffect(() => {
    if (!socket.current) return
    socket.current.on("change-password-response", (data) => {
      setChangePasswordMessage([data[0]])
      if (data[0] !== "Success") return

      if (typeof data[1].usersettings === "string")
        data[1].usersettings = JSON.parse(data[1].usersettings)
      if (typeof data[1].owned === "string")
        data[1].owned = JSON.parse(data[1].owned)

      setUser(data[1])
    })

    return () => {
      socket.current.off("change-password-response")
    }
  }, [socket.current])

  function changePassword(password, newPassword, confirmPassword, e) {
    e.preventDefault()
    socket.current.emit("change-password", {
      userName: user.userName,
      email: user.email,
      password,
      newPassword,
      confirmPassword,
    })
  }

  return (
    <>
      <div className="gameFontColors accountPageBox">
        <h1 className="accountPageBoxCentering">Account</h1>
        <div className="accountPageBoxCentering">
          <form>
            <h2 className="accountPageBoxCentering">Change Password</h2>
            <h2>{changePasswordMessage}</h2>
            <div className="inputBox">
              <input
                type="password"
                required
                onChange={(e) => {
                  e.preventDefault()
                  setPassword(e.target.value)
                }}
              />
              <span>Current Password</span>
              <i></i>
            </div>
            <div className="inputBox">
              <input
                type="password"
                required
                onChange={(e) => {
                  e.preventDefault()
                  setNewPassword(e.target.value)
                }}
              />
              <span>New Password</span>
              <i></i>
            </div>
            <div className="inputBox">
              <input
                type="password"
                required
                onChange={(e) => {
                  e.preventDefault()
                  setConfirmPassword(e.target.value)
                }}
              />
              <span>Confirm Password</span>
              <i></i>
            </div>
            <div className="accountPageBoxCentering">
              <input
                type="submit"
                value="Change Password"
                onClick={(e) =>
                  changePassword(password, newPassword, confirmPassword, e)
                }
              />
            </div>

          </form>
        </div>
      </div>
    </>
  )
}

export default Account
