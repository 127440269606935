const GameResult = ({ gameObject: { date, gameId, win, opponent } }) => {
  const watchReplay = () => {
    // Logic to watch replay by gameId (if available)
    console.log(`Attempting to watch replay for gameId: ${gameId}`);
  };

  return (
    <div className="game-result-card">
      <h2 className="opponent-name">Match vs {opponent}</h2>

      {/* Conditionally style victory/defeat text */}
      <div className={`game-outcome ${win ? "victory" : "defeat"}`}>
        {win ? "Victory!" : "Defeat!"}
      </div>

      <div className="game-date">{date}</div>
      <button className="replay-button" onClick={watchReplay}>
        Watch Replay
      </button>
    </div>
  );
};

export default GameResult;