import { useContext, useEffect, useState } from "react";
import { GameContext } from "../../App";
import StatDisplay from "../../components/statsDisplay";

const CardStats = () => {
  const { user, socket, cards } = useContext(GameContext);

  // State to hold stats for all years
  const [allCardStats, setAllCardStats] = useState({});

  // State to hold the selected year
  const [selectedYear, setSelectedYear] = useState("2025");

  // Extract the stats for the selected year
  const cardStats = allCardStats[selectedYear] || [];

  useEffect(() => {
    if (socket.current) {
      socket.current.emit("request-card-stats");

      socket.current.on("receive-card-stats", (data) => {
        setAllCardStats(data);
      });
    }

    return () => {
      if (socket.current) {
        socket.current.off("receive-card-stats");
      }
    };
  }, [socket]);

  return (
    <div className="gameFontColors">
      <h1>Card Stats</h1>
      <div className="biggerText">
        <div className="biggerText">Your Win/Loss</div>
        {`Wins: ${user.wins}  Losses: ${user.losses}  Win Rate: ${
          user.wins + user.losses === 0
            ? "N/A"
            : `${Math.round((user.wins / (user.wins + user.losses)) * 100)}%`
        }`}
      </div>

      {/* Year Selection */}
      <div className="year-selection">
        <label htmlFor="year-select">Select Year:</label>
        <select
          id="year-select"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          {Object.keys(allCardStats).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      {/* Pass the stats for the selected year to StatDisplay */}
      <StatDisplay stats={cardStats} cards={cards} user={user} />
    </div>
  );
};

export default CardStats;