import "./styles/cardBack.css"
import "./styles/3dStyles.css"
import "./styles/navMenu.css"
import "./styles/card.css"
import "./styles/contactForm.css"
import "./styles/signUpLogin.css"
import "./styles/board.css"
import "./styles/hiddenPopups.css"
import "./styles/deckbuilder.css"
import "./styles/generic.css"
import "./styles/mediaQueries.css"
import "./styles/store.css"
import "./styles/cardStats.css"
import "./styles/glossary.css"
import Navbar from "./components/Navbar"
import { Routes, Route, useLocation } from "react-router-dom"
import Game from "./pages/game"
import DeckBuilder from "./pages/deckbuilder"
import Contact from "./pages/contact"
import Authentication from "./pages/authentication"
import Profile from "./components/Auth/profile"
import Store from "./pages/store"
import CardDetail from "./pages/carddetail"
import ReloadGame from "./pages/reloadGame"
import { useState, createContext, useEffect, useRef } from "react"
import useSocket from "./customHooks/useSocket"
import { QueryClient, QueryClientProvider } from "react-query"
import CardStore from "./pages/cardstore"
import soundEffects from "./gameSFX/SFX"
// import ResetPassword from "./components/Auth/resetPassword"
import ResetPasswordConfirm from "./pages/resetPasswordConfirm"
import keyWordFile from "./keywordMessages.json"

// Global Variables
export const GameContext = createContext(null)

function App() {
  const socket = useSocket()
  const [deck, setDeck] = useState([])
  const [sideBoard, setSideBoard] = useState([])
  const [user, setUser] = useState({ userName: "Guest" })
  const [signedIn, setSignedIn] = useState(false)
  const [cards, setCards] = useState([])
  const [rooms, setRooms] = useState([])
  const [deckList, setDeckList] = useState([])
  const [customDecksTrue, setCustomDecksTrue] = useState(false)
  const [deckName, setDeckName] = useState("")
  const [isMuted, setIsMuted] = useState(false)
  const [showNavbar, setShowNavbar] = useState(false)
  const location = useLocation()
  const queryClient = new QueryClient()
  const playPageSwitch = useRef(true)
  const [playersOnline, setPlayersOnline] = useState(0)
  const [signUpMessages, setSignUpMessages] = useState([])
  const [signUpRedirect, setSignUpRedirect] = useState(false)
  const [displayGlossary, setDisplayGlossary] = useState(false)
  const [glossarySearchTerm, setGlossarySearchTerm] = useState("")

  useEffect(() => {
    if (socket.current.inQueue) {
      socket.current.emit("leave-queue", user)
      socket.current.inQueue = false
      socket.current.activeGame = false
    }
    if (playPageSwitch.current) {
      soundEffects.pageSwitch()
      playPageSwitch.current = false
      setTimeout(() => (playPageSwitch.current = true), 300)
    }
    if (user?.usersettings?.automaticMenuHide === false) {
      setShowNavbar(true)
    } else if (user?.usersettings?.automaticMenuHide === true) {
      setShowNavbar(false)
    }
  }, [location])

  useEffect(() => {
    socket.current.on("send-card-list", (cards) => {
      setCards(cards.sort((a, b) => a.cost - b.cost))
    })

    socket.current.on("load-decks-response", (data) => {
      const allDecks = []
      for (let i = 0; i < data.length; i++) {
        if (data[i]?.name) {
          const deck = {
            name: data[i]?.name,
            cardList: JSON.parse(data[i].deck),
            sideBoard: JSON.parse(data[i].sideboard),
          }
          allDecks.push(deck)
        }
      }
      setDeckList(allDecks)
      setCustomDecksTrue(true)
    })

    socket.current.on("players-online", (data) => {
      setPlayersOnline(data)
    })

    socket.current.on("rooms", (data) => {
      setRooms(data)
    })

    socket.current.on("send-user", (data) => {
      if (data) {
        const userUpdate = data
        userUpdate.usersettings = JSON.parse(userUpdate.usersettings)
        userUpdate.owned = JSON.parse(userUpdate.owned)
        setUser(userUpdate)
      }
    })

    socket.current.on("socket-id", (id) => {
      socket.current.socketID = id
    })

    socket.current.on("sign-in-response", (data) => {
      if (data.loginResponse[0] === "Success") {
        const signedInUser = data.loginResponse[1]
        if (typeof signedInUser.usersettings === "string")
          signedInUser.usersettings = JSON.parse(signedInUser.usersettings)

        if (typeof signedInUser.owned === "string")
          signedInUser.owned = JSON.parse(signedInUser.owned)

        setSignedIn(true)
        setUser(signedInUser)
        socket.current.uniqueId = data.uniqueId
        socket.current.user = signedInUser
      }
    })

    socket.current.on("disconnect-user", () => {
      setSignedIn(false)
      setUser({ userName: "Guest" })
      setCustomDecksTrue(false)
    })

    socket.current.on("sign-up-response", (data) => {
      if (data[0] === "Success") {
        setSignUpRedirect(true)
      } else {
        setSignUpMessages(data)
      }
    })

    socket.current.on("sign-in-response", (data) => {
      if (data.loginResponse[0] === "Success") {
        const signedInUser = data.loginResponse[1]
        if (typeof signedInUser.usersettings === "string")
          signedInUser.usersettings = JSON.parse(signedInUser.usersettings)
        if (typeof signedInUser.owned === "string")
          signedInUser.owned = JSON.parse(signedInUser.owned)
        setSignUpMessages([data.loginResponse[0]])
        setSignedIn(true)
        setUser(signedInUser)
        socket.current.uniqueId = data.uniqueId
      } else {
        setSignUpMessages(data.loginResponse)
      }
    })

    socket.current.on("send-reset-password-response", (data) => {
      setSignUpMessages(data)
    })
  }, [])

  useEffect(() => {
    if (signedIn) {
      if (user.usersettings.automaticMenuHide === false) {
        setShowNavbar(true)
      }
      socket.current.emit("load-decks", user.email)
    }
  }, [signedIn])

  useEffect(() => {
    if (user?.usersettings?.muteSound) {
      setIsMuted(true)
    } else {
      setIsMuted(false)
    }
    if (user.usersettings?.alternateStyles) {
      setAlternateCardStyles()
    }
  }, [user])

  useEffect(() => {
    if (isMuted) {
      soundEffects.muteSounds()
    } else {
      soundEffects.unmuteSounds()
    }
  }, [isMuted])

  function setAlternateCardStyles() {
    const newCardList = [...cards]

    if (!user.usersettings?.alternateStyles) return
    const alternativeStyles = user.usersettings?.alternateStyles
    for (let i = 0; i < alternativeStyles.length; i++) {
      const foundCard = newCardList.find(
        (card) => card.name === alternativeStyles[i].name
      )
      if (alternativeStyles[i]?.art && foundCard) {
        foundCard.image = alternativeStyles[i]?.art
      }
    }
    setCards(newCardList.sort((a, b) => a.cost - b.cost))
  }

  return (
    <div id="container" className="container">
      <QueryClientProvider client={queryClient}>
        <GameContext.Provider
          value={{
            deck,
            setDeck,
            sideBoard,
            setSideBoard,
            user,
            setUser,
            signedIn,
            setSignedIn,
            deckList,
            setDeckList,
            deckName,
            isMuted,
            showNavbar,
            setShowNavbar,
            setIsMuted,
            setDeckName,
            customDecksTrue,
            cards,
            rooms,
            socket,
            playersOnline,
            signUpMessages,
            setSignUpMessages,
            signUpRedirect,
            setSignUpRedirect,
            displayGlossary,
            setDisplayGlossary,
          }}
        >
          <Navbar />
          <Routes>
            <Route path="/play" element={<Game />} />
            <Route path="/deckbuilder" element={<DeckBuilder />} />
            <Route path="/contact" element={<Contact />} />
            <Route path={"/"} element={<Authentication />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path={"/cards/:id"} element={<CardDetail />} />
            <Route path="/store" element={<Store />} />
            <Route path="/store/card/:id" element={<CardStore />} />
            <Route path={"/reload"} element={<ReloadGame />} />
            <Route path={"/reset"} element={<ResetPasswordConfirm />} />
          </Routes>
        </GameContext.Provider>
      </QueryClientProvider>
      {displayGlossary && (
        <div className="glossaryDiv">
          {/* Glossary Banner (unchanged) */}
          <div className="glossaryBanner">
            <h2 className="banner-title">Glossary</h2>
            <p className="banner-subtitle">
              Warning: To Close Glossary, use the button below or click “?” to return.
            </p>
          </div>

          {/* Main glossary content */}
          <div className="glossaryContent">
            {/* 
               Group the search bar and close button in a single container
               so they appear on the same line.
             */}
            <div className="glossaryActions">
              <div className="inputSearchBar searchBarCorrection focusCorrection">
                <input
                  value={glossarySearchTerm}
                  onChange={(e) => setGlossarySearchTerm(e.target.value)}
                  type="text"
                  name="search"
                  required
                />
                <span id="fontSizeCorrection" className="glossarySpanFix">Search Glossary</span>
                <i className="glossaryInputFix"></i>
              </div>
            
              <button
                className="biggerButton closeGlossaryButton"
                onClick={() => setDisplayGlossary(false)}
              >
                Close Glossary
              </button>
            </div>
            
            {/* Glossary Entries */}
            <div className="glossaryEntries">
              {Object.entries(keyWordFile)
                .sort((a, b) => a[0].localeCompare(b[0]))
                .filter(
                  (entry) =>
                    entry[0].toLowerCase().includes(glossarySearchTerm.toLowerCase()) ||
                    entry[1].toLowerCase().includes(glossarySearchTerm.toLowerCase())
                )
                .map((entry) => (
                  <div className="glossaryEntry" key={entry[0]}>
                    {`${entry[0].slice(0, 1).toUpperCase()}${entry[0].slice(1)}: ${
                      entry[1]
                    }`}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default App
